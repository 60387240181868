import React, { Component } from 'react'
import  './homepage.scss'
import Header from './header/Header'
import JoinUs from './joinUs/JoinUs'
import Coursers from './coursers/Coursers'
import Gallery from './gallery/Gallery'
import Coummuntiy from './coummuntiy/Coummuntiy'
import Branches from './branches/Branches'
import { connect } from 'react-redux'
import AOS from 'aos';
import 'aos/dist/aos.css';

 class HomePage extends Component {

      componentDidMount(){
        AOS.init({
          duration : 2000
        })
      }
      

    render() {
        return (
            <div className="homePage" >
                <Header/>
                <JoinUs/>
                <Coursers />
                <Gallery/>
                <Coummuntiy/>
                <Branches/>
            </div>
        )
    }
}



export default connect()(HomePage) 
