import React, { Component, Fragment } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./navbar.scss";
import logo_real from "../../../image/logo_real_soft1.png";
import english from "../../../image/england.png";
import egypt from "../../../image/egypt.png";
import AOS from "aos";
import "aos/dist/aos.css";
import menu_symbol from "../../../image/menu_symbol.png";
import {coursesApi, courseCategoriesApi, onlineCoursesApi} from '../../../store/actions/Courses'
import {brancheApi} from '../../../store/actions/branches'
import {chooseJob} from '../../../store/actions/job'
import {headerApi} from '../../../store/actions/header'
import {galleryApi} from '../../../store/actions/gallery'
import {connect} from 'react-redux'
import i18n from "i18next";
import { Translation } from "react-i18next";
import { Helmet } from "react-helmet";

  const storageLang = localStorage.getItem('langLocalcStorage')
  var finalLang = storageLang == null ? 'en' : storageLang

  // var finalLang = storageLang === null ? navigator.language : storageLang

class NavbarWowmix extends Component {
  componentDidMount() {
    AOS.init({
      duration: 2000
    });
  }

  state = {
    toggleMenuBar: null,
    lang: finalLang
  };

  toggleMenu = () => {
    this.setState({ toggleMenuBar: !this.state.toggleMenuBar });
  };

  changeLanguage = lng => {
    localStorage.setItem("langLocalcStorage", lng);
    this.props.coursesApi(lng)
    this.props.brancheApi(lng)
    this.props.chooseJob(lng)
    this.props.headerApi(lng)
    this.props.galleryApi(lng)
    this.props.courseCategoriesApi(lng)
    this.props.onlineCoursesApi();
    i18n.changeLanguage(lng);
    this.setState({ lang: lng });
  };

  render() {
    return (
      <Fragment>
        <Helmet>
          <html
            lang={this.state.lang}
            dir={this.state.lang === "en" ? "ltr" : "rtl"}
          ></html>
        </Helmet>
        <Navbar
          className="NavbarWowmix d-none d-sm-block"
          bg="dark"
          variant="dark"
          expand="lg"
        >
          <div className="container">
            <Link to="/">
              <img src={logo_real} className="img-fluid logo-navbar" alt="real soft"/>
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mx-auto">
                <Translation>
                  {t => (
                    <Link to="/" className="mx-2">
                      {t("home_menu")}
                    </Link>
                  )}
                </Translation>
                <Translation>
                  {t => (
                    <Link to="/about-us" className="mx-2">
                      {t("about_menu")}
                    </Link>
                  )}
                </Translation>
                <Translation>
                  {t => (
                    <Link to="/courses" className="mx-2">
                      {t("courses_menu")}
                    </Link>
                  )}
                </Translation>
                <Translation>
                  {t => (
                    <Link to="/gallery" className="mx-2">
                      {t("gallery_menu")}
                    </Link>
                  )}
                </Translation>
                <Translation>
                  {t => (
                    <Link to="/branches" className="mx-2">
                      {t("branches_menu")}
                    </Link>
                  )}
                </Translation>
                <Translation>
                  {t => (
                    <Link to="/jobs" className="mx-2">
                      {t("jobs_menu")}
                    </Link>
                  )}
                </Translation>
                <Translation>
                  {t => (
                    <Link to="/student-registration" className="mx-2">
                      {t("student_menu")}
                    </Link>
                  )}
                </Translation>
                <Translation>
                  {t => (
                    <Link to="/contact-us" className="mx-2">
                      {t("contact_menu")}
                    </Link>
                  )}
                </Translation>
              </Nav>
              <Nav className=" text-right flot-right">
                <Translation>
                  {t => (
                    <Nav.Link
                      onClick={() => this.changeLanguage("ar")}
                      className="float-right text-white "
                    >
                      <img src={egypt} className="img-fluid" alt="Arabic" /> {' '}
                      {/* {t("arabic_menu")} */}
                    </Nav.Link>
                  )}
                </Translation>
                <Translation>
                  {t => (
                    <Nav.Link
                      onClick={() => this.changeLanguage("en")}
                      className="float-right text-white "
                    >
                      <img src={english} className="img-fluid" alt="English" /> {''}
                      {/* {t("english_menu")} */}
                    </Nav.Link>
                  )}
                </Translation>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
        <div className="parent-menu-mobile bg-dark bg-info p-3 py-4 d-block d-sm-none">
          <div className="container">
            <h6 className="my-0 py-0 text-white ">
              {" "}
              RealSoftHouse
              <img
                src={menu_symbol}
                className="img-fluid float-right menu-icon"
                alt="real soft"
                onClick={this.toggleMenu}
              />
            </h6>
          </div>
        </div>
        {this.state.toggleMenuBar ? (
          <div
            className="menu-mobile-screen p-4 "
            data-aos="zoom-out-down"
            data-aos-duration="2500"
          >
            <div className="border-bottom pb-4 header-menu ">
            <Translation>
                  {t => (
                    <h6 className="d-inline ">
                      {" "}
                       {t("heading_menu")}
                      <span
                        className="d-inline-block float-right text-white close-menu "
                        onClick={this.toggleMenu}
                      >
                        X{" "}
                      </span>
                    </h6>
                  )}
                </Translation>

              
              <span onClick={this.toggleMenu} className="d-block float-left">
                {" "}
                <i className="fas fa-times"></i>{" "}
              </span>
            </div>
            <div className="mt-3 ">
              <ul
                className="list-unstyled mt-5 text-center pr-0"
                data-aos="zoom-in"
                data-aos-duration="6000"
              >
                <li className="text-center" >
                  <Translation>
                    {t => (
                      <Link to="/" onClick={this.toggleMenu} className="">
                        {t("home_menu")}
                      </Link>
                    )}
                  </Translation>
                </li>
                <li className="text-center">
                  <Translation>
                    {t => (
                      <Link to="/about-us" onClick={this.toggleMenu}>
                        {t("about_menu")}
                      </Link>
                    )}
                  </Translation>
                </li>
                <li className="text-center">
                  <Translation>
                    {t => (
                      <Link to="/courses" onClick={this.toggleMenu}>
                        {t("courses_menu")}
                      </Link>
                    )}
                  </Translation>
                </li>
                <li className="text-center">
                  <Translation>
                    {t => (
                      <Link to="/gallery" onClick={this.toggleMenu}>
                        {t("gallery_menu")}
                      </Link>
                    )}
                  </Translation>
                </li>
                <li className="text-center">
                  <Translation>
                    {t => (
                      <Link to="/branches" onClick={this.toggleMenu}>
                        {t("branches_menu")}
                      </Link>
                    )}
                  </Translation>
                </li>
                <li className="text-center">
                  <Translation>
                    {t => (
                      <Link to="/jobs" onClick={this.toggleMenu}>
                        {t("jobs_menu")}
                      </Link>
                    )}
                  </Translation>
                </li>
                <li className="text-center">
                  <Translation>
                    {t => (
                      <Link to="/student-registration" onClick={this.toggleMenu}>
                        {t("student_menu")}
                      </Link>
                    )}
                  </Translation>
                </li>
                <li className="text-center">
                  <Translation>
                    {t => (
                      <Link to="/contact-us" onClick={this.toggleMenu}>
                        {t("contact_menu")}
                      </Link>
                    )}
                  </Translation>
                </li>
                <li className="text-center">
                  <Translation>
                    {t => (
                      <Nav.Link
                        onClick={() => this.changeLanguage("ar")}
                        className="text-white text-center "
                      >
                        <img src={egypt} className="img-fluid" alt="Arabic" /> {' '}
                        {t("arabic_menu")}
                      </Nav.Link>
                    )}
                  </Translation>
                </li>
                <li className="text-center">
                  <Translation>
                    {t => (
                      <Nav.Link
                        onClick={() => this.changeLanguage("en")}
                        className=" text-white text-center "
                      >
                        <img src={english} className="img-fluid" alt="English" /> {''}
                        {t("english_menu")}
                      </Nav.Link>
                    )}
                  </Translation>
                </li>
              </ul>
            </div>
            <div
              className="path"
              data-aos="fade-left"
              data-aos-duration="2500"
            ></div>
            <div
              className="path2"
              data-aos="fade-left"
              data-aos-duration="5000"
              data-aos-delay="3500"
            ></div>
            <div
              className="path3"
              data-aos="fade-right"
              data-aos-duration="5000"
              data-aos-delay="3500"
            ></div>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

export default connect(null, {coursesApi, brancheApi, chooseJob, headerApi, galleryApi, courseCategoriesApi, onlineCoursesApi })(NavbarWowmix);
