import React, { Component } from "react";
import "./branches.scss";
import {brancheApi} from '../../../store/actions/branches'
import {connect} from 'react-redux'
import BrancheSummary from "./BrancheSummary";
import { Translation } from "react-i18next";


const storageLang = localStorage.getItem('langLocalcStorage')
var finalLang = storageLang == null ? 'en' : storageLang

// var finalLang = storageLang == null ? navigator.language : storageLang

class Branches extends Component {

  componentDidMount = () => {
    this.props.brancheApi(finalLang)
  } 

  render() {

   const {brancheData} = this.props;
    
    return (
      <div className="Branches py-5 mt-md-5 ">
        <div className="container">
          <div className="header-photo-gallery ">
              <Translation>
                {t => 
                  <h1 className="text-center" data-aos='zoom-in' data-aos-duration="3000">
                    {t("Branches_Name")}
                  </h1>
                }
              </Translation>
            <div className="row">
              <div className="col-md-10 mx-auto">
                <Translation>
                  {t => 
                    <p className="text-center" data-aos='zoom-in' data-aos-duration="3000">
                      {t("Branches_desc")}
                    </p>
                  }
                </Translation>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            {brancheData ? brancheData.map((item,index) => {
              return (
                <BrancheSummary 
                  id={item.id}
                  key={item.id}
                  data={item}
                  countIndes={index}
                />
              )
            }) : null }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    brancheData : state.brancheData
  }
}

const mapDispatchToProps = dispatch => {
  return{
    brancheApi : (lang) => dispatch(brancheApi(lang)),
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(Branches);





