import React, { Component } from "react";
import { connect } from "react-redux";
import placeholder from "../../image/placeholder.jpg";
import "./coursers.scss";
import {course_id_Api} from '../../store/actions/Courses'
import { imageUrl } from "../../urls/urls";
import RegCourses from '../regCourses/RegCourses' 
import RegCoursesStepTow from '../regCourses/RegCoursesStepTow' 

class CourseDesc extends Component {

  state = {
    first: false,
    steps : false
  }

  handleSteps = () => {
    this.setState({first : !this.state.first })
  }

  
  componentDidMount = () => {
   const id = this.props.match.params.CourseDetail_id;
    this.props.course_id_Api(id)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.studentStepOne.status === 200) {
      this.setState({ steps: !this.state.steps, first: !this.state.first  });
    }
  }

  
  componentWillUnmount(){
    this.setState({steps : !this.state.steps  })
  }


  render() {
    const { coursesData, studentStepOne } = this.props
    const Cid = this.props.match.params.CourseDetail_id
    const course_category_id = coursesData.course_category_id

    return (
      <div className="CourseDesc py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="header-desc-course bg-white p-4 border rounded mb-4  shadow">
                <h1 className="h3 mb-0">
                  {coursesData ? coursesData.name : null}
                  <span className="float-right text-info"> {coursesData ? coursesData.cost : null}LE </span>
                </h1>
              </div>
              </div>
              <div className="col-md-3 ml-auto">
                <div className="header-desc-course bg-white p-4 border rounded mb-4  shadow">
                  <button onClick={this.handleSteps} className="btn btn-info d-block btn-block">
                    {" "}
                    Register Now{" "}
                  </button>
                </div>
              </div>
          </div>

          {this.state.first ? 
           <RegCourses course_id={Cid} course_category_id={course_category_id} />    
          : null }

          {this.state.steps ? 
           <RegCoursesStepTow />      
          : null }

          <div className="description border rounded p-4 shadow">
            <div className="row">
              <div className="col-md-5">
                {coursesData &&
                    <img
                    src={ coursesData.image ? imageUrl+ coursesData.image : placeholder }
                    className="img-fluid rounded"
                    alt="realsoft"
                  />
                }
               
              </div>
              <div className="col-md-7">
                <h5> About Course </h5>
                <p>
                 {coursesData ? coursesData.description : null }
                </p>
                
                <ul className="list-unstyled pr-0">
                  <li>
                    {" "}
                    Level: <span> { coursesData ? coursesData.levels : null } </span>{" "}
                  </li>
                  <li>
                    {" "}
                    Course Hours: <span> { coursesData ? coursesData.hours : null } </span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    coursesData: state.course_id,
    studentStepOne: state.studentStepOne,
    studentStepThree: state.studentStepThree,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    course_id_Api: data => dispatch(course_id_Api(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseDesc);
