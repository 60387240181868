import {baseUrl} from '../../urls/urls'
import axios from 'axios'


export const headerApi = (lang) => dispatch => {
    axios.get(baseUrl+`assets-by-category?asset_category=Header&local=${lang}`)
    .then(header => {
        dispatch({
            type: 'HEADER_SUCSSESS',
            payload: header.data
        })
    })
    .catch(err => {
        dispatch({
            type: 'HEADER_ERR',
            payload: err
        })
    })  
}