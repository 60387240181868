import React, { Component } from "react";
import "./aboutUs..scss";
import logo_about from '../../image/logo_about.svg'
import {imageUrl} from '../../urls/urls'
import video from '../../video/wowmix.mp4'
import {videoApi} from '../../store/actions/video'
import {connect} from 'react-redux'
import poster_v from '../../image/photo_r4.jpg'
import Polygon_back from '../../image/Polygon_back.svg' 
import Polygon_front from '../../image/Polygon_front.svg' 
import AOS from 'aos';
import { Translation } from "react-i18next";

import 'aos/dist/aos.css';

const storageLang = localStorage.getItem('langLocalcStorage')
var finalLang = storageLang == null ? 'en' : storageLang

// var finalLang = storageLang == null ? navigator.language : storageLang

class AboutUs extends Component {

  componentDidMount(){
    this.props.videoApi(finalLang)

    AOS.init({
      duration : 2000
    })
  }
  

  render() {

    const {videoData} = this.props

    
    return (

      <div className="AboutUs">
         <div className="header-ahout">
          <div className="container py-5">
            <div className="col-md-6 pt-5">
              <img src={logo_about} className="img-fluid " alt="logo" ata-aos='fade-right' data-aos-duration="3000" />
            </div>
          </div>
        </div>
        <div className="container py-5">
            <div className="col-md-10 mx-auto">
              <div className="row">
                <div className="col-md-6 ">
                  <Translation>
                      {t => (
                        <h1 className="h3 mt-0 mt-4" data-aos='fade-left' data-aos-duration="3000" >
                            {t("about_realsoft")}
                        </h1>
                      )}
                  </Translation>
                  <Translation>
                      {t => (
                        <p className="mt-3" >
                            {t("about_realsoft_desc")}
                        </p>
                      )}
                  </Translation>
                </div>
                <div className="col-md-5 offset-md-1" data-aos='fade-right' data-aos-duration="3000" data-aos-delay="600">
                  {videoData.image ?   
                    <video controls="controls" poster={poster_v} className="embed-responsive embed-responsive-16by9" >
                        <source src={imageUrl+videoData.image} type="video/mp4"/>
                       Your browser does not support HTML5 video.
                    </video>
                  : null}

                 
                </div>
              </div>
               
                <div className="row mt-2">
                  <div className="col-12">
                  <Translation>
                      {t => (
                        <p className="mt-2 mb-5" data-aos='fade-up' data-aos-duration="3000">
                            {t("about_realsoft_desc2")}
                        </p>
                      )}
                  </Translation>
                  </div>
                  <div className="col-md-6">
                    <Translation>
                      {t => (
                        <h5 data-aos='zoom-in' data-aos-duration="3000">  
                            {t("vision_title")}
                        </h5>
                      )}
                  </Translation>
                    <Translation>
                      {t => (
                      <p className="" data-aos='zoom-in' data-aos-duration="3000" >
                         {t("vision_desc")}
                       </p>
                      )}
                  </Translation>
                  </div>
                  <div className="col-md-6">
                  <Translation>
                      {t => (
                        <h5 data-aos='zoom-in' data-aos-duration="3000">  
                            {t("mission_title")}
                        </h5>
                      )}
                  </Translation>
                    <Translation>
                      {t => (
                      <p className="" data-aos='zoom-in' data-aos-duration="3000" >
                         {t("mission_desc")}
                       </p>
                      )}
                  </Translation>
                  </div>
                  <div className="col-md-12">
                      <Translation>
                          {t => (
                            <h5 data-aos='zoom-in' data-aos-duration="3000">  
                                {t("Value_title")}
                            </h5>
                          )}
                      </Translation>
                        <Translation>
                          {t => (
                          <ul className="" data-aos='zoom-in' data-aos-duration="3000">
                            <li> {t("Value_desc")} </li>
                            <li> {t("Value_desc2")} </li>
                            <li> {t("Value_desc3")} </li>
                          </ul>
                          )}
                      </Translation>
                  </div>
                </div>
            </div>
            <div className="Polygon_back">
                <img src={Polygon_back} className="img-fluid"  alt="Polygon_back"/>
            </div>
            <div className="Polygon_front">
                <img src={Polygon_front} className="img-fluid"  alt="Polygon_front"/>
            </div>
        </div>

     

      </div>

    );
  }
}

const mapStateToProps = state => {
  return {
    videoData: state.video
  }
}


export default connect(mapStateToProps, {videoApi})(AboutUs)