import { baseUrl } from '../../urls/urls'
import axios from 'axios'

export const videoApi = (lang) => dispatch => {
    axios.get(baseUrl+`assets-by-category?asset_category=Video&local=${lang}`)
    .then( video => {
        dispatch({
            type: 'VIDEO_SUCSSESS',
            payload: video.data
        })
    })
    .catch(err => {
        dispatch({
            type: 'VIDEO_ERR', 
            payload: err
        })
        if(err.response) {
        }
    })
} 

