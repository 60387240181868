import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      "title button": "Registration",
      'home_menu' : 'Home',
      'about_menu':'About Us',
      'courses_menu' : 'Courses',
      'gallery_menu': 'Gallery',
      'branches_menu': 'Branches',
      'jobs_menu': 'Jobs',
      'student_menu' : 'Registration',
      'contact_menu' : 'Contact Us',
      'arabic_menu' : 'العربيه',
      'english_menu' : 'English',
      'heading_menu' : 'Menu',
      'site_map' : 'Site Map',
      'descHeader' : 'Welcome to the official website of "Real Soft House for Software and Training". This site is specially designed to help both clients and learners to know all the educational and training services provided by the Company and how to get benefit effectively.',
      'Social_Media' : 'Social Media',
      'copyright' : ' copyright all rights reserved',
      'Why_Join_Us' : 'Why Join Us',
      'Join_Desc' : 'Why you should be one of our clients?',
      'card_one' : 'Company offers appropriate',
      'card_tow' : 'history of the Company',
      'card_three' : 'Our distinguished geographical',
      'card_four' : 'Training hours',
      'card_five' : '24/7 Customer Service',
      'card_one_desc' : 'Because the Company offers appropriate opportunities to learn and master languages ​​and computers skills for various age groups throughout the various educational and professional stages.',
      'card_tow_desc' : 'The history of the Company is 10 continuous years of success and more than 250 thousand trainees are qualified in the field of languages ​​and computers.',
      'card_three_desc' : "Our distinguished geographical spread all over Egypt, where the company's branches are located in 7 different governorates (with a total of 14 branches) and work is underway to open other branches in the other governorates.",
      'card_four_desc' : 'Availability of study schedules throughout the week during the morning and evening periods, with an average of 10,000 training hours available to all learners at all the branches.',
      'card_five_desc' : 'The existence of a qualified customer service team ready to assist clients and to respond to all inquiries 24/7 through the hotline, social media and front desk representatives at the branches.',
      'course_heading' : 'Courses',
      'course_desc' : 'Choose the course you want to study now',
      'view_all' : 'View All',
      'Photo_Gallery' : 'Photo Gallery',
      'Gallery_desc' : 'live images from our company',
      'coummuntiy' : 'Real Soft House In Numbers',
      'coummuntiy_desc' : 'Real Soft House in numbers',
      'Students_title' : 'Students',
      'Branches_title' : 'Branches',
      'Employees_title':'Instructors',
      'course_count' : 'Courses',
      'Branches_Name' : 'Branches',
      'Branches_desc' : 'We are always close to you through our branches’ network located in 7 different governorates.',
      'see_more' : ' See More',

      
      // about page
      'about_realsoft' : 'About Real Soft House',
      'about_realsoft_desc' : "We started the company in 2010 by opening the first branch at Faisl. At that time Fasil’s branch was serving only software programming to the trainees and out of nowhere January’s revolution has happened and the numbers of the students have decreased, so as a result to that the company started to change the strategy and the vision. We started to give English courses alongside with the software programming courses by given refundable scholarships to help the trainees fitting into the job market.",
      'about_realsoft_desc2' : 'The company has earned the trainees’ trust throw a great reputation that we have gained . since 2015 we have started to expand by opening more branches: - Al Abasia in March 2015. - The 6th of October in July 2015. - Nasr City in January 2016. - Al Mohandsen in February 2016. - Al Maadi in August 2016 . In 2016 we thought about expanding all over Egypt, so we set the direction to the beautiful city of Alexandria. In July of 2016 we opened the first branch in Alexandria in Gleem. Our magnificent reputation has arrived to Alexandria before us, so because of all the trainees that have registered for the courses we opened another branch in Somha in September 2016 followed by opening Al Agami Branch, the third branch in Alexandria. We set a new direction to The Delta’s cities and we picked Al Mansora followed by Tanta back to Upper Egypt in Asyout . After our expansion outside Cairo, we returned to the Capital of Egypt to Cairo; by opening a new branch in Shobra.',
      'vision_title' : 'vision',
      'vision_desc' : 'We seek to achieve leadership in the field of teaching and practicing languages ​​in Egypt according to the latest teaching methods and approved educational curricula.',
      'mission_title' : 'mission',
      'mission_desc' : 'Learning and mastering languages ​​has become an urgent necessity for everyone. From this standpoint, the company seeks to provide a set of grants and educational programs for universities, institutes, and alumni students, including paid ones, free ones and those with economic fees, in order to achieve the principle of participation in community development.',
      'Value_title': 'Value',
      'Value_desc': 'Assisting the largest possible number of individuals and institutions in learning and practicing languages and computers',
      'Value_desc2': 'Instilling a culture of self-development among young people by encouraging them to strive towards learning languages or modern technical sciences ',
      'Value_desc3': 'Preparing new generations with multiple skills besides the basic academic qualification to make them able to compete in the labor market, whether inside or outside Egypt.',
     
      // gallery page
      'Gallery_card_title' : 'Gallery_card_title',
      'Gallery_card_desc' : 'Gallery_card_desc',


      // courses page
      'courses_card_title_one' : 'English Introduction Course',
      'courses_card_desc_one' : "The trainee shall learn the basics and principles of the English language, which qualifies him / her to join the first level of any course or scholarship offered by the Company. Duration of study: One month and a half ( 8 hours per level) ",
      'courses_card_title_tow' : 'Main English Courses',
      'courses_card_desc_tow' : "The trainee shall learn the basics and principles of the English language, which qualifies him / her to join the first level of any course or scholarship offered by the Company. Duration of study: One month and a half ( 8 hours per level) ",
      'courses_card_title_three' : 'English Introduction Course',
      'courses_card_desc_three' : "The trainee shall learn the basics and principles of the English language, which qualifies him / her to join the first level of any course or scholarship offered by the Company. Duration of study: One month and a half ( 8 hours per level) ",
      'placeholder_message' : 'Message',

      // job page
      'job_title' : 'Job Registration',
      'placeholder_name' : 'Name',
      'placeholder_phone' : 'Phone',
      'placeholder_studying' : 'Your Studies',
      'placeholder_choose_jobs' : 'Choose Job',
      'placeholder_branche' : 'Choose Branch',
      'placeholder_Experience' : 'Experience',
      'placeholder_Adress' : 'Address',
      'paceholder_Note' : 'Notes',
      'send_button' : 'Send',

      // students registration
      'child_registration' : 'Child Registration',
      'parent_full_Name' : 'Parent Full Name',
      'child_name' : 'Child Name',
      'child_age' : 'Child Age',
      'Student_Registration' : 'Student Registration',
      'student_full_Name' : 'Full Name',
      'student_first_Name' : 'First Name',
      'student_last_Name' : 'Last Name',
      'student_mobile' : 'Mobile',
      'Student_select_branch' : 'Choose Branch',
      'Student_Email' : 'Email',
      'student_University' : 'University',
      'Student_Select_College' : 'College',
      'student_National_id' : 'National id',
      'student_Adress' : 'Address',
      'student_note' : 'لما بتحجز بتدفع ٤٠٠ ج لكل شهر عضوية وتدرس اللي انتا عايزة او اختار باقة من دول للحصول علي افضل سعر',
      'package_one' : 'The Bronze Package',
      'package_one_desc' : 'Consists of 3 months \n' +
          'You will take what you miss in your English after conducting the placement test for free',
      'package_tow' : 'The Silver Package',
      'package_tow_desc' : 'Consists of 6 months \n' +
      'You will study everything about English from the scratch including grammar, vocabulary and conversation after you go through the placement test',
      'package_three' : 'The Golden Package',
      'package_three_desc' : 'Consists of 12 months \n' +
          'You study everything in English and during this period you can revise them for free',
      'package_four' : 'The Platinum Package',
      'package_four_desc' : 'You have 2 full years you can take all the courses that the company provides without any additional or hidden charges',
      'button_back' : 'Back',
      'button_Next' : 'Next',
      'button_Finish' : 'Finish',

      // contact us 
      'TeacherRegistration' : 'Teacher Registration',
      'TeacherRegistration_desc' : 'Join our training team',
      'StudentRegistration' : 'Student Registration',
      'StudentRegistration_desc' : ' Be one of our clients',
      'contactUS_title' : 'Contact Us',
      'contactUS_desc' : 'Be in touch with us, we are happy to contact you',
      'text_from_contact' : '',
      'button_sent' : 'send',

      // course reg
      'Course_Registration': 'Course Registration',
      'Submit': 'Submit',

    }
  },
  ar: {
    translation: {
      "title button": "سجل الان",
      'home_menu' : 'الرئيسية',
      'about_menu':'من نحن',
      'courses_menu' : 'الدورات',
      'gallery_menu': 'الصور',
      'branches_menu': 'الفروع',
      'jobs_menu': 'الوظائف',
      'student_menu' : 'تسجيل الطلاب',
      'contact_menu' : 'تواصل معنا',
      'arabic_menu' : 'العربيه',
      'english_menu' : 'English',
      'heading_menu' : 'القائمة',
      'site_map' : 'خريطه الموقع',
      'Social_Media' : 'تابعنا على السوشيال ميديا',
      'copyright' : ' جميع الحقوق محفوظة ',
      'Why_Join_Us' : 'لماذا نحن ',
      'Join_Desc' : ' لماذا تكون أحد عملاءنا',
      'card_one' : 'الفرص المناسبة',
      'card_tow' : 'تاريخ الشركة',
      'card_three' : 'الانتشار الجغرافي',
      'card_four' : 'الساعات التدريبية',
      'card_five' : 'خدمة عملاء ٢٤/٧',
      'card_one_desc' : ' تقدم الشركة الفرص المناسبة لتعلم واتقان اللغات والكمبيوتر بالنسبة للفئات العمرية المتنوعة وعلى مدار مختلف المراحل التعليمية والمهنية. ',
      'card_tow_desc' : ' يمثل تاريخ الشركة 10 سنوات متواصلة من النجاح تم خلالها تخريج اكثر من 250 الف متدرب في مجال اللغات والكمبيوتر.',
      'card_three_desc' : '- الانتشار الجغرافي المميز في مختلف انحاء الجمهورية ، حيث تتواجد فروع الشركة في 7 محافظات متنوعة ( باجمالي 14 فرع ) وجاري العمل علي فتح فروع اخري في باقي المحافظات الاخري.',
      'card_four_desc' : '- توافر مواعيد للدراسة طوال الايام الاسبوع خلال الفترتين الصباحية والمسائية ، وبمتوسط 10.000 ساعة تدريبية متاحة لجميع الدارسين بجميع فروع الشركة .',
      'card_five_desc' : '- وجود فريق خدمة عملاء مؤهل وجاهز لمساعدة العملاء والرد علي جميع استفساراتهم علي مدار 24 ساعة يوميا من خلال الخط الساخن والسوشيال ميديا وموظفي الاستقبال بالفروع.',
      'course_heading' : 'الدورات المتاحه',
      'course_desc' : 'اختر الدورة التدربيبية المناسبه لك وابدء الان',
      'view_all' : 'عرض الكل',
      'Photo_Gallery' : 'عرض الصور',
      'Gallery_desc' : 'مجموعه من الصور الواقعيه من داخل الشركه',
      'coummuntiy' : 'احصائيات عامة',
      'coummuntiy_desc' : 'ريل سوفت في ارقام',
      'Students_title' : 'طلاب',
      'Branches_title' : 'الفروع',
      'Employees_title':'المدربين',
      'course_count' : 'الدورات',
      'Branches_Name' : 'الفروع',
      'Branches_desc' : 'نحن دائما الاقرب اليك من خلال شبكة فروعنا المتواجدة في 7 محافظات مختلفة:',

      // about page
      'about_realsoft' : 'التعريف بالشركه ونبذه عامه',
      'about_realsoft_desc' : 'بدأنا الشركة في عام 2010 بافتتاح أول فرع لها في فيصل. في ذلك الوقت ، كان فرع شركة Fasil يخدم فقط برمجية البرامج للمتدربين وخارج أي مكان حدثت فيه ثورة يناير وانخفض عدد الطلاب ، ونتيجة لذلك بدأت الشركة في تغيير الاستراتيجية والرؤية. لقد بدأنا في تقديم دورات اللغة الإنجليزية جنبًا إلى جنب مع دورات برمجة البرمجيات من خلال منح دراسية قابلة للاسترداد لمساعدة المتدربين المناسبين في سوق العمل.',
      'about_realsoft_desc2' : 'حصلت الشركة على ثقة المتدربين في سمعة طيبة اكتسبناها. منذ عام 2015 ، بدأنا بالتوسع من خلال فتح المزيد من الفروع: - العباسية في مارس 2015. - السادس من أكتوبر في يوليو 2015. - مدينة نصر في يناير 2016. - المهندسين في فبراير 2016. - المعادي في أغسطس 2016. في عام 2016 ، فكرنا في التوسع في جميع أنحاء مصر ، لذلك حددنا الاتجاه إلى مدينة الإسكندرية الجميلة. في يوليو من عام 2016 ، افتتحنا الفرع الأول في الإسكندرية في جليم. وصلت سمعتنا الرائعة إلى الإسكندرية قبلنا ، لذا نظرًا لجميع المتدربين الذين سجلوا في الدورات التي افتتحناها فرعًا آخر في سومها في سبتمبر 2016 ، تلا ذلك افتتاح فرع العجمي ، الفرع الثالث في الإسكندرية. وضعنا اتجاهًا جديدًا لمدن الدلتا واخترنا المنصورة تليها طنطا للعودة إلى صعيد مصر في أسيوط. بعد توسعنا خارج القاهرة ، عدنا إلى عاصمة مصر إلى القاهرة ؛ من خلال افتتاح فرع جديد في شبرا.',
      'vision_title' : 'الرؤيه',
      'vision_desc' : 'بنتظار الترجمه نسعي لتحقيق الريادة في مجال تعليم وممارسة اللغات في مصر وفقا لاحدث اساليب التدريس والمناهج التعليمية المعتمدة.',
      'mission_title' : 'الرسالة',
      'mission_desc' : 'إن تعلم واتقان اللغات اصبح ضرورة ملحة بالنسبة للجميع ومن هذا المنطلق تسعي الشركة لتقديم مجموعة من المنح والبرامج التعليمية لطلبة الجامعات والمعاهد والخريجين ، منها المدفوع ومنها المجاني ومنها ذات الرسوم الأقتصادية ، وذلك تحقيقا لمبدا المشاركة في التنمية المجتمعية',
      'Value_title': 'القيمة',
      'Value_desc': 'مساعدة اكبر عدد ممكن من الافراد والمؤسسات في تعلم وممارسة اللغات والكمبيوتر ',
      'Value_desc2': 'غرس ثقافة تطوير الذات لدي الشباب وذلك بتشجيعهم على السعي نحو تعلم اللغات او العلوم التقنية الحديثة',
      'Value_desc3': 'تخريج اجيال تمتلك مهارات متعددة بجانب المؤهل العلمي الاساسي تجعلها قادرة على المنافسة في سوق العمل سواء داخل او خارج مصر',
     

       // gallery page
       'Gallery_card_title' : 'بنتظار',
       'Gallery_card_desc' : 'بنتظار',
    
      // job page
      'job_title' : 'بادر بالانضمام الينا',
      'placeholder_name' : 'الاسم',
      'placeholder_phone' : 'رقم الموبايل',
      'placeholder_studying' : 'الدراسه',
      'placeholder_choose_jobs' : 'اختر الوظيفه',
      'placeholder_branche' : 'اختر الفرع',
      'placeholder_Experience' : 'الخبره',
      'placeholder_Adress' : 'العنوان',
      'paceholder_Note' : 'الملاحظات',
      'send_button' : 'ارسال',

      // students registration
      'child_registration' : 'تسجيل كورس الاطفال',
      'parent_full_Name' : 'اسم ولي الامر بالكامل',
      'child_name' : 'اسم الطفل',
      'child_age' : 'عمر الطفل',
      'Student_Registration' : 'تسجيل الطلاب',
      'student_full_Name' : 'الاسم بالكامل',
      'student_first_Name' : 'الاسم الاول',
      'student_last_Name' : 'اسم العائلة',
      'student_mobile' : 'الموبايل',
      'Student_select_branch' : 'الفرع',
      'Student_Email' : 'الايميل',
      'student_University' : 'الجامعه',
      'Student_Select_College' : 'الكلية',
      'student_National_id' : 'الرقم القومى',
      'student_Adress' : 'العنوان',
      'student_note' : 'لما بتحجز بتدفع ٤٠٠ ج لكل شهر عضوية وتدرس اللي انتا عايزة او اختار باقة من دول للحصول علي افضل سعر',
      'package_one' : 'الباقه البرونز',
      'package_one_desc' : '٣ شهور بتدرس معانا انجليزي بشكل مرن في المواعيد اللي تناسبك بدون رسوم اضافية',
      'package_tow' : 'الباقه السيلفر',
      'package_tow_desc' : '٦ شهور بتدرس فيهم اي كورس انجليزي تختاره في المواعيد المناسبة ليك بدون اي رسوم اضافية طوال مدة الدراسة',
      'package_three' : 'الباقه الجولد',
      'package_three_desc' : '١٢ شهر بتدرس فيهم كل حاجه عن الانجليزي من الاول للاخر وكمان ممكن تراجع عليهم خلال الفتره دي بالاضافة لكورسات تانية ممكن تختارها',
      'package_four' : 'الباقه البلاتينيوم',
      'package_four_desc' : 'سنتين بتدرس كل كورسات الشركه بدون اي رسوم اضافيه خلال السنتين وتقدر توقف وتبدا وقت ما انت عايز وتشترك في كل انشطة الشركة مجانا',
      'button_back' : 'رجوع',
      'button_Next' : 'التالى',
      'button_Finish' : 'انتهاء',
      'see_more' : 'عرض المزيد',

      // contact us 
      'TeacherRegistration' : 'تسجيل الوظائف',
      'TeacherRegistration_desc' : 'الانضمام لفريق العمل',
      'StudentRegistration' : 'تسجيل الطلاب',
      'StudentRegistration_desc' : 'كن احد عملاءنا',
      'contactUS_title' : 'تواصل معنا',
      'contactUS_desc' : 'كن على اتصال معنا ، نحن سعداء بالاتصال بك',
      'text_from_contact' : '',
      'placeholder_message' : 'رسالتك',
      'button_sent' : 'ارسال',

      // course reg
      'Course_Registration': 'تسجيل الدورة',
      'Submit': 'تاكيد',
    }
  }
};

  const storageLang = localStorage.getItem('langLocalcStorage')
  var finalLang = storageLang == null ? 'ar' : storageLang

  // var finalLang = storageLang == null ? navigator.language : storageLang
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: finalLang,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;