import React, {Component} from "react";
import {brancheApi} from "../../store/actions/branches";
import {reset, childReg} from "../../store/actions/studentRegApi";
import {pixelsApi} from "../../store/actions/pixels";
import {connect} from "react-redux";
import Swal from "sweetalert2";
import {Translation} from "react-i18next";
import success from "../../image/seccess.svg";
import {Link} from "react-router-dom";

const storageLang = localStorage.getItem("langLocalcStorage");
var finalLang = storageLang === null ? 'en' : storageLang;

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: toast => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    }
});

class ChildReg extends Component {
    state = {
        fullName: "",
        mobile: "",
        branch_id: "",
        child_name: "",
        child_age: "",
        sec: false
    };

    componentDidMount = () => {
        // const hoursDay = 1000 * 60 * 60 * 24;
        // setInterval(function() {
        //   localStorage.removeItem("realSoftHpuse_AdID");
        //   alert("Hello");
        // }, hoursDay);
        this.props.brancheApi(finalLang);
        // const check_AdID = localStorage.getItem("realSoftHpuse_AdID");
        // const new_AdID = this.props.match.params.lead_id;

        // if (check_AdID) {
        //   this.props.pixelsApi(check_AdID);
        // } else if (new_AdID) {
        //   const new_AdID = this.props.match.params.lead_id;
        //   this.props.pixelsApi(new_AdID);
        //   localStorage.setItem("realSoftHpuse_AdID", new_AdID);
        // } else {
        //   // alert("normall")
        // }
    };

    componentDidUpdate() {
        if (this.props.child_reg.data) {
            Toast.fire({
                icon: this.props.child_reg.data.type,
                title: this.props.child_reg.data.message
            });
        }
        if (this.props.child_reg.data?.status == 'CompleteRegistration') {
            window.fbq('track', 'CompleteRegistration', {
                value: 0.50,
                currency: 'USD'
            });
            window.gtag('event', 'conversion', {
                'send_to': 'AW-876896264/lxv3CKP4zdEBEIjAkaID'
            });
        } else if (this.props.child_reg.data?.status == 'Lead') {
            window.fbq('track', 'Lead');
            window.gtag('event', 'Lead', {
                'send_to': 'AW-876896264/lxv3CKP4zdEBEIjAkaID'
            });
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.id]: e.target.value
        });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const {fullName, mobile, branch_id, child_name, child_age} = this.state;
        const regex = /01[0-2|5]{1}[0-9]{8}/
        if (!fullName) {
            Toast.fire({
                icon: "error",
                title: "Please enter the Parent Full Name"
            });
        } else if (!regex.test(mobile)) {
            Toast.fire({
                icon: "error",
                title: "Please enter valid mobile number"
            });
        } else if (!branch_id) {
            Toast.fire({
                icon: "error",
                title: "Please Select Branch"
            });
        } else if (!child_name) {
            Toast.fire({
                icon: "error",
                title: "Please enter the child name"
            });
        } else if (!child_age) {
            Toast.fire({
                icon: "error",
                title: "Please enter the child age"
            });
        } else {
            const lead_ad_id = new URLSearchParams(this.props.location.search).get("lead_ad_id");
            const inputs = lead_ad_id ? {...this.state, lead_ad_id} : this.state;
            this.props.childReg(inputs);
            if (this.props.child_reg.status === 200) {
                this.setState({
                    fullName: "",
                    mobile: "",
                    branch_id: "",
                    child_name: "",
                    child_age: ""
                });
                // this.props.Done();
            }
        }
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.child_reg.status === 200) {
            this.setState({sec: !this.state.sec});
        }
    }

    componentWillUnmount() {
        this.props.reset();
    }

    render() {
        const {pixels} = this.props;
        if (pixels) {
            localStorage.setItem("pixelsStorage", JSON.stringify(pixels));
            const pStorage = JSON.parse(localStorage.getItem("pixelsStorage"));
        }

        const {branches} = this.props;
        return (
            <div className="">
                <div className="container py-5">
                    <Translation>
                        {t => <h1 className="text-center">{t("child_registration")}</h1>}
                    </Translation>
                    {!this.state.sec ? (
                        <div className="mx-auto col-md-11 border p-4 mt-5 ">
                            <form
                                onSubmit={this.handleSubmit}
                                noValidate
                                autoComplete="off"
                                className="my-3"
                            >
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <Translation>
                                                {t => (
                                                    <input
                                                        onChange={this.handleChange}
                                                        type="text"
                                                        placeholder={t("parent_full_Name")}
                                                        id="fullName"
                                                        className="form-control form-control-lg"
                                                        value={this.state.fullName}
                                                    />
                                                )}
                                            </Translation>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <Translation>
                                                {t => (
                                                    <input
                                                        type="tel"
                                                        pattern="^01[0-2|5]{1}[0-9]{8}"
                                                        maxLength="11"
                                                        onChange={this.handleChange}
                                                        placeholder={t("student_mobile")}
                                                        id="mobile"
                                                        className="form-control form-control-lg"
                                                        value={this.state.mobile}
                                                    />
                                                )}
                                            </Translation>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <select
                                                id="branch_id"
                                                onChange={this.handleChange}
                                                className="form-control form-control-lg"
                                            >
                                                <Translation>
                                                    {t => (
                                                        <option value="1">
                                                            {t("Student_select_branch")}{" "}
                                                        </option>
                                                    )}
                                                </Translation>
                                                {branches
                                                    ? branches.map(item => {
                                                        return (
                                                            <option key={item.id} value={item.id}>
                                                                {" "}
                                                                {item.name}{" "}
                                                            </option>
                                                        );
                                                    })
                                                    : null}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <Translation>
                                                {t => (
                                                    <input
                                                        type="text"
                                                        onChange={this.handleChange}
                                                        placeholder={t("child_name")}
                                                        id="child_name"
                                                        className="form-control form-control-lg"
                                                        value={this.state.child_name}
                                                    />
                                                )}
                                            </Translation>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <Translation>
                                                {t => (
                                                    <input
                                                        type="number"
                                                        onChange={this.handleChange}
                                                        placeholder={t("child_age")}
                                                        id="child_age"
                                                        className="form-control form-control-lg"
                                                        value={this.state.child_age}
                                                    />
                                                )}
                                            </Translation>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4 ml-auto">
                                        <Translation>
                                            {t => (
                                                <button className="btn btn-info btn-block btn-lg mt-4">
                                                    {" "}
                                                    {t("Submit")}{" "}
                                                </button>
                                            )}
                                        </Translation>
                                    </div>
                                </div>
                            </form>
                        </div>
                    ) : (
                        <div className="text-center">
                            <img
                                src={success}
                                className="img-fliud d-block mx-auto"
                                alt="success"
                            />
                            <h5 className="text-center">
                                {" "}
                                Your details have been successfully registered - thanks for your
                                trust{" "}
                            </h5>
                            <Link to="/" className="btn btn-info">
                                {" "}
                                Home{" "}
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        branches: state.brancheData,
        child_reg: state.child_reg,
        pixels: state.pixels
    };
};

const mapDispatchToProps = dispatch => {
    return {
        childReg: data => dispatch(childReg(data)),
        reset: () => dispatch(reset()),
        brancheApi: lang => dispatch(brancheApi(lang)),
        pixelsApi: ad_id => dispatch(pixelsApi(ad_id))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChildReg);
