import React, { Component } from "react";
import { connect } from "react-redux";
import "./regCourses.scss";
import CoursesSummary from "../coursers/CoursesSummary";
import { brancheApi } from "../../store/actions/branches";
import { stepOne, reset } from "../../store/actions/studentRegApi";
import Swal from "sweetalert2";
import { Translation } from "react-i18next";

const storageLang = localStorage.getItem("langLocalcStorage");
var finalLang = storageLang === null ? 'en' : storageLang;

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: toast => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  }
});

class RegCourses extends Component {
  state = {
    fullName: "",
    mobile: "",
    branch_id: "",
    course_id : this.props.course_id,
    course_category_id : this.props.course_category_id,
  };

  componentDidMount = () => {
    const Cid = this.props.course_id
    this.props.brancheApi(finalLang);
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { fullName, mobile, branch_id } = this.state;
    const regex = /01[0-2|5]{1}[0-9]{8}/
    if (!fullName) {
      Toast.fire({
        icon: "error",
        title: "Please Enter Full Name"
      });
    } else if (!regex.test(mobile)) {
      Toast.fire({
        icon: "error",
        title: "Please Enter Valid Mobile Number"
      });
    } else if (!branch_id) {
      Toast.fire({
        icon: "error",
        title: "Please Select Branch"
      });
    } else {
      this.props.stepOne(this.state);
      if (this.props.studentStepOne.status === 200) {
        alert("sent success");
        this.setState({
          fullName: "",
          mobile: "",
          branch_id: ""
        });
        // this.props.Done();
      }
    }
  };

 

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const { branches } = this.props;
    return (
      <div className="RegCourses description border rounded shadow p-4 mb-3">
        <div className="row">
          <div className="col-md-4">
            <h4 className="mt-2" >Register Course</h4>
          </div>
          <div className="col-md-5 ml-auto">
            <span className="item-steps  d-inline-block">
              <h6 className="text-center " > 2 </h6>
            </span>
            <span className="item-steps bg-info d-inline-block">
              <h6 className="text-center " > 1 </h6>
            </span>
          </div>
        </div>
        <form
          onSubmit={this.handleSubmit}
          noValidate
          autoComplete="off"
          className="my-3 mt-4"
        >
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <Translation>
                  {t => (
                    <input
                      onChange={this.handleChange}
                      type="text"
                      placeholder={t("student_full_Name")}
                      id="fullName"
                      className="form-control form-control-lg"
                      value={this.state.fullName}
                    />
                  )}
                </Translation>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Translation>
                  {t => (
                      <input
                          type="tel"
                          pattern="^01[0-2|5]{1}[0-9]{8}"
                          maxLength="11"
                          onChange={this.handleChange}
                          placeholder={t("student_mobile")}
                          id="mobile"
                          className="form-control form-control-lg"
                          value={this.state.mobile}
                      />
                  )}
                </Translation>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <select
                  id="branch_id"
                  onChange={this.handleChange}
                  className="form-control form-control-lg"
                >
                  <Translation>
                    {t => (
                      <option value="1">{t("Student_select_branch")} </option>
                    )}
                  </Translation>
                  {branches
                    ? branches.map(item => {
                        return <option value={item.id}> {item.name} </option>;
                      })
                    : null}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 ml-auto">
              <Translation>
                {t => (
                  <button className="btn btn-info btn-block btn-lg mt-4">
                    {" "}
                    {t("button_Next")}{" "}
                  </button>
                )}
              </Translation>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    branches: state.brancheData,
    studentStepOne: state.studentStepOne,
    studentStepTow: state.studentStepTow
  };
};

const mapDispatchToProps = dispatch => {
  return {
    stepOne: data => dispatch(stepOne(data)),
    reset: () => dispatch(reset()),
    brancheApi: lang => dispatch(brancheApi(lang))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegCourses);
