import React, { Component } from "react";
import { connect } from "react-redux";
import logo_online from "../../image/logo_online.png";
import contactPathRight from "../../image/contact-path-right.svg";
import contactPathLeft from "../../image/contact-path-left.svg";
import "./onlineReg.scss";
import StudySystem from "./StudySystem";
import Cost from "./Cost";
import CostEG from "./CostEG";
import PaymentMethod from "./PaymentMethod";
import PaymentMethodEG from "./PaymentMethodEG";
import Question from "./Question";
import PrevStudents from "./PrevStudents";
import { defaultONlineCourse } from "../../store/actions/Courses";
import { Helmet } from "react-helmet";
import queryString from 'query-string';
import Cookies from 'universal-cookie';
import ReactGA from 'react-ga';
ReactGA.initialize('AW-876896264');
ReactGA.pageview(window.location.pathname + window.location.search);

function Items({ item, setItem }) {
  switch (item) {
    case "StudySystem":
      return <StudySystem setItem={setItem} />;
    case "Cost":
      return <Cost setItem={setItem} />;
    // case "CostEG":
    //   return <CostEG setItem={setItem}/>;
    case "PaymentMethod":
      return <PaymentMethod setItem={setItem} currency={"USD"} />;
    case "Question":
      return <Question setItem={setItem} />;
    case "PrevStudents":
      return <PrevStudents setItem={setItem} />;
    default:
      return <StudySystem />;
  }
}
function EGPItem({ item, setItem }) {
  switch (item) {
    case "StudySystem":
      return <StudySystem setItem={setItem} />;
    // case "Cost":
    //   return <Cost setItem={setItem}/>;
    case "Cost":
      return <CostEG setItem={setItem} />;
    case "PaymentMethod":
      return <PaymentMethodEG setItem={setItem} currency={"EGP"} />;
    case "Question":
      return <Question setItem={setItem} />;
    case "PrevStudents":
      return <PrevStudents setItem={setItem} />;
    default:
      return <StudySystem />;
  }
}

class OnlineReg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: "StudySystem"
    };
  }
  componentDidMount(){
    const params = queryString.parse(this.props.location.search);
    params.status && this.setItem("PaymentMethod")
    const cookies = new Cookies();
    if (this.props.currency){
      if(params.egyptian_student_fb_ad_id && params.egyptian_student_fb_ad_id != cookies.get('egyptian_student_fb_ad_id'))
      cookies.set('egyptian_student_fb_ad_id', params.egyptian_student_fb_ad_id, { maxAge: 3600 * 24});
    }else {
      if(params.foreign_student_fb_ad_id && params.foreign_student_fb_ad_id != cookies.get('foreign_student_fb_ad_id'))
      cookies.set('foreign_student_fb_ad_id', params.foreign_student_fb_ad_id, { maxAge: 3600 * 24});
    }
    window.history.replaceState(null, null, window.location.pathname);
  }
  setItem = item => {
    this.setState({ item });
  };
  render() {
    const { item } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <html dir={"rtl"} lang="ar" ></html>
        </Helmet>

        <div className="OnlineReg">
          <div className="container">
            <img
              src={logo_online}
              className="img-fluid d-block mx-auto mt-5"
              alt="real soft"
            />
            <div className="contact-path-right">
              <img
                src={contactPathRight}
                className="img-fluid contactPathRight"
                alt=""
              />
            </div>
            <div className="contact-path-left">
              <img
                src={contactPathLeft}
                className="img-fluid contactPathLeft"
                alt=""
              />
            </div>
            <ul
              className="nav nav-pills mb-3 mt-5 py-4 text-center mx-auto px-0"
              id="pills-tab"
              role="tablist"
            >
              <li
                onClick={e => {
                  this.setItem("StudySystem");
                  this.props.defaultONlineCourse();
                }}
                className={`nav-link text-center mx-auto ${item ==
                  "StudySystem" && "active"}`}
              >
                نظام ومدة الدراسة
              </li>
              <li
                onClick={e => {
                  this.setItem("Cost");
                  this.props.defaultONlineCourse();
                }}
                className={`nav-link text-center mx-auto ${item == "Cost" &&
                  "active"}`}
              >
                تكلفة الكورس
              </li>
              <li
                onClick={e => {
                  this.setItem("PaymentMethod");
                  this.props.defaultONlineCourse();
                }}
                className={`nav-link text-center mx-auto ${item ==
                  "PaymentMethod" && "active"}`}
              >
                طرق الدفع
              </li>
              <li
                onClick={e => {
                  this.setItem("Question");
                  this.props.defaultONlineCourse();
                }}
                className={`nav-link text-center mx-auto ${item == "Question" &&
                  "active"}`}
              >
                أسئلة و استفسارات
              </li>
              <li
                onClick={e => {
                  this.setItem("PrevStudents");
                  this.props.defaultONlineCourse();
                }}
                className={`nav-link text-center mx-auto ${item ==
                  "PrevStudents" && "active"}`}
              >
                الخريجين السابقين
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              {this.props.currency ? (
                <EGPItem item={item} setItem={this.setItem} />
              ) : (
                <Items item={item} setItem={this.setItem} />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default connect(null, { defaultONlineCourse: defaultONlineCourse })(
  OnlineReg
);
