import React, { Component } from "react";
import slider_study from "../../image/slider_study.jpg";
import slider_study_2 from "../../image/slider_study_2.jpg";
import reg_online_image from "../../image/reg_online.jpg";
import healthy from "../../image/healthy.jpg";
import bg_note from "../../image/bg_note.jpg";
import { Navgator, SignForCourse, Chat } from "./navgator";
import {onlineSliderApi} from '../../store/actions/gallery'
import {imageUrl} from '../../urls/urls'
import {connect} from 'react-redux'


const storageLang = localStorage.getItem('langLocalcStorage')
var finalLang = storageLang == null ? 'en' : storageLang


class StudySystem extends Component {


  componentDidMount = () => {
    this.props.onlineSliderApi(finalLang)
  }

  render() {
    const {onlineSliderData} = this.props
    return (
      <div className="mt-5 StudySystem pb-5">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-ride="carousel"
        >
          {/* <ol className="carousel-indicators">
            <li
              data-target="#carouselExampleIndicators"
              data-slide-to="0"
              className="active"
            ></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          </ol> */}
          <div className="carousel-inner">

          {onlineSliderData.map((slide, index ) => {
                  return (
                        <div className={`carousel-item ${index == 0 ? 'active' : '' }
                      `} key={index}>
                          <img
                            src={imageUrl+slide.image}
                            className="d-block w-100 "
                            alt="img"
                          />
                          <div className="overlay-image">
                            <h1 className="text-center h3 text-white mt-5">
                              خبرة 13 سنة في مجال تعليم اللغات والتدريس
                            </h1>
                            <p className="text-center text-white mt-4">
                              {" "}
                              سجل الان واحصل علي خصم 80%{" "}
                            </p>
                            <div className="mx-auto px-3 d-block w-25 mt-4">
                              <SignForCourse setNav={this.props.setItem} />
                            </div>
                          </div>
                        </div>
                  );
                })
              }
          
{/* 
            <div className="carousel-item active">
              <img src={slider_study} className="d-block w-100" alt="..." />
              <div className="overlay-image">
                <h1 className="text-center h3 text-white mt-5">
                  خبرة 13 سنة في مجال تعليم اللغات والتدريس
                </h1>
                <p className="text-center text-white mt-4">
                  {" "}
                  سجل الان واحصل علي خصم 80%{" "}
                </p>
                <div className="mx-auto px-3 d-block w-25 mt-4">
                  <SignForCourse setNav={this.props.setItem} />
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img src={slider_study_2} className="d-block w-100" alt="..." />
              <div className="overlay-image">
                <h1 className="text-center h3 text-white mt-5">
                  خبرة 13 سنة في مجال تعليم اللغات والتدريس
                </h1>
                <p className="text-center text-white mt-4">
                  {" "}
                  سجل الان واحصل علي خصم 80%{" "}
                </p>
                <div className="mx-auto px-3 d-block w-25 mt-4">
                  <SignForCourse setNav={this.props.setItem} />
                </div>
              </div>
            </div> */}

          </div>
          <a
            className="carousel-control-prev"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
        <div className="row mt-5 pt-5">
          <div className="col-md-4 ">
            <div className="parent-overlay">
              <img src={reg_online_image} className="d-block w-100" alt="..." />
              <div className="overlay-image">
                <p className="p-4 text-white w-50 mt-5">
                  {" "}
                  انضم الينا وسارع بالتسجيل لتحقيق هدفك في التعلم و التميز{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-7 offset-md-1 mt-5">
            <h4 className="advice">
              حرصاً منا على سلامتكم وسهولة عملية التعليم وأن تتاح للجميع من شتى
              أنحاء العالم العربي
            </h4>
            <p className="mt-4">
              {" "}
              وبعد خبرة أكثر من ثلاثة عشر عاماً في مجال تدريب اللغات فى جميع
              أنحاء جمهورية مصر العربية فقد أطلقت أخيراً ريل سوفت منصتها
              الإلكترونية لتعليم اللغة الإنجليزية أون لاين لتكوم متاحه بكل سهولة
              ويسر لجميع دول العالم العربي{" "}
            </p>
          </div>
        </div>

        <div className="row mt-5 pt-5">
          <div className="col-md-7">
            <h4>نظام ومدة الدراسة</h4>
            <p className="mt-4">
              تتم الدراسة من خلال منصة برنامج Zoom الذي يتيح للدارسين من جميع
              إنحاء العالم مشاهدة المحاضر والتفاعل معه اثناء المحاضرة تماما كما
              يحدث داخل قاعات الدراسة ، كذلك يسمح لهم بالمشاركة من خلال التواصل
              الصوتي او بالظهور المرئي ايضا ( صوت وصورة )
            </p>
            <p>
              - يدرس الكورس بواقع 6 ساعات لكل مستوي دراسي ، ويكون ذلك بمعدل
              محاضرتين في الاسبوع ، بواقع ساعتين للمحاضرة الواحدة
            </p>
            <p>
              مع امكانية فتح مواعيد محاضرة واحدة في الاسبوع ، مدتها 3 ساعات ،
              يوم الجمعه او السبت ( في حالة وجود اقبال من العملاء علي مواعيد
              اليوم الواحد )
            </p>
          </div>
          <div className="col-md-4 offset-md-1">
            <img src={healthy} className="d-block w-100" alt="..." />
          </div>
        </div>

        <div className="note mt-5 w-100">
          <div className="row">
            <div className="col-md-12">
              <div className="content-note">
                <h5 className="text-white text-center p-3">
                  ويحصل المتدرب على شهادتين في نهاية الدراسة شهادة إلكترونية
                  وشهادة ورقية ترسل على عنوانه المسجل تثبت مستواه وإجتيازه جميع
                  الإختبارات بإعتماد من ريل سوفت لإستخدامها
                  في سوق العمل
                </h5>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <Navgator prev="" next="Cost" setNav={this.props.setItem} />
        </div>
      </div>
    );
  }
}

const mapStateToProp = state => {
  return {
    onlineSliderData: state.onlineSliderData
  };
};

const mapDispatchToProps = dispatch => {
  return{
    onlineSliderApi : (lang) => dispatch(onlineSliderApi(lang)),
  }
}



export default connect(mapStateToProp, mapDispatchToProps )(StudySystem) 
