import React, { Component } from "react";
import "./coummuntiy.scss";
import { connect } from "react-redux";
import { coummuntiyApi } from "../../../store/actions/coummuntiy";
import coummuntiy_0 from "../../../image/coummuntiy_0.svg";
import coummuntiy_1 from "../../../image/coummuntiy_1.svg";
import coummuntiy_2 from "../../../image/coummuntiy_2.svg";
import coummuntiy_3 from "../../../image/coummuntiy_3.svg";
import coummuntiy_right from "../../../image/coummuntiy_right.svg";
import coummuntiy_left from "../../../image/coummuntiy_left.svg";
import { Translation } from "react-i18next";

class Coummuntiy extends Component {
  componentDidMount = () => {
    this.props.coummuntiyApi();
  };

  render() {
    var { get_countData } = this.props;

    return (
      <div className="coummuntiy" ref={this.myRef}>
        <div className="container mt-md-5">
          <Translation>
            {t => (
              <h1
                className="text-center"
                data-aos="zoom-in"
                data-aos-duration="3000"
              >
                {t("coummuntiy")}
              </h1>
            )}
          </Translation>
          <div className="row">
            <div className="col-md-10 mx-auto">
              <Translation>
                {t => (
                  <p
                    className="text-center"
                    data-aos="zoom-in"
                    data-aos-duration="3000"
                  >
                    {t("coummuntiy_desc")}
                  </p>
                )}
              </Translation>
            </div>
          </div>
          <div className="row mt-5 pt-4">
            {/* item */}
            <div className="col-md-3 col-6">
              <div
                className="coummuntiy-item mb-3 bg-white p-3 rounded border pt-4"
                data-aos="flip-right"
                data-aos-duration="3000"
              >
                <img
                  src={coummuntiy_0}
                  className="img-fluid d-block mx-auto"
                  alt="coummuntiy_img"
                />
                <Translation>
                  {t => (
                    <h6 className="text-center mt-3">{t("Students_title")}</h6>
                  )}
                </Translation>
                <h5 className="text-center">
                  {" "}
                  {get_countData ? get_countData["students_count"] : null}{" "}
                </h5>
              </div>
            </div>
            {/* item */}
            <div className="col-md-3 col-6">
              <div
                className="coummuntiy-item mb-3 bg-white p-3 rounded border pt-4"
                data-aos="flip-right"
                data-aos-duration="3000"
              >
                <img
                  src={coummuntiy_1}
                  className="img-fluid d-block mx-auto"
                  alt="coummuntiy_img"
                />
                <Translation>
                  {t => (
                    <h6 className="text-center mt-3">{t("course_count")}</h6>
                  )}
                </Translation>
                <h5 className="text-center">
                  {" "}
                  {get_countData ? get_countData["courses_count"] : null}{" "}
                </h5>
              </div>
            </div>
            {/* item */}
            <div className="col-md-3 col-6">
              <div
                className="coummuntiy-item mb-3 bg-white p-3 rounded border pt-4"
                data-aos="flip-right"
                data-aos-duration="3000"
              >
                <img
                  src={coummuntiy_2}
                  className="img-fluid d-block mx-auto"
                  alt="coummuntiy_img"
                />
                <Translation>
                  {t => (
                    <h6 className="text-center mt-3">{t("Branches_title")}</h6>
                  )}
                </Translation>
                <h5 className="text-center">
                  {" "}
                  {get_countData ? get_countData["branches_count"] : null}{" "}
                </h5>
              </div>
            </div>
            {/* item */}
            <div className="col-md-3 col-6">
              <div
                className="coummuntiy-item mb-3 bg-white p-3 rounded border pt-4"
                data-aos="flip-right"
                data-aos-duration="3000"
              >
                <img
                  src={coummuntiy_3}
                  className="img-fluid d-block mx-auto"
                  alt="coummuntiy_img"
                />
                <Translation>
                  {t => (
                    <h6 className="text-center mt-3">{t("Employees_title")}</h6>
                  )}
                </Translation>
                <h5 className="text-center">
                  {" "}
                  {get_countData ? get_countData["instructors_count"] : null}{" "}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="clip-path-right">
          <img
            src={coummuntiy_right}
            className="img-fluid"
            alt="coummuntiy_right"
          />
        </div>
        <div className="clip-path-left">
          <img
            src={coummuntiy_left}
            className="img-fluid"
            alt="coummuntiy_left"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    get_countData: state.get_countData
  };
};

export default connect(mapStateToProps, { coummuntiyApi })(Coummuntiy);
