import React from "react";
import placeholder from "../../../image/placeholder.png";
import { imageUrl } from "../../../urls/urls";
import {Link} from 'react-router-dom'

const BrancheSummary = ({ data, countIndes }) => {
  return (
    <div
      className={
        countIndes === 0
          ? "col-md-3 col-6"
          : countIndes === 1
          ? "col-md-2 col-6"
          : countIndes === 2
          ? "col-md-2 col-6"
          : countIndes === 3
          ? "col-md-3 col-6"
          : countIndes === 4
          ? "col-md-2 col-6"
          : countIndes === 5
          ? "col-md-2 col-6"
          : countIndes === 6
          ? "col-md-3 col-6"
          : countIndes === 7
          ? "col-md-3 col-6"
          : countIndes === 8
          ? "col-md-2 col-6"
          : countIndes === 9
          ? "col-md-2 col-6"
          : countIndes === 10
          ? "col-md-3 col-6"
          : countIndes === 11
          ? "col-md-2 col-6"
          : countIndes === 12
          ? "col-md-3 col-6"
          : countIndes === 13 && "col-md-4 col-6"
      }
    >
      <Link to={{pathname:"/Branches", state:{ branchId: data.id}}}>
        <div
          className="Branches-item mb-4"
          data-aos="zoom-in"
          data-aos-duration="3000"
        >
          <img
            src={data.photo ? imageUrl + data.photo : placeholder}
            className="img-fluid"
            alt="real soft"
          />

          <div className="overlay-desc">
            <h6> {data ? data.name : null} </h6>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default BrancheSummary;
