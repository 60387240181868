import { baseUrl } from '../../urls/urls'
import axios from 'axios'


export const stepOne = (data) => dispatch => {

    let bodyFormData = new FormData();
    bodyFormData.append('name',data.fullName)
    bodyFormData.append('phone',data.mobile)
    bodyFormData.append('mobile',data.mobile)
    bodyFormData.append('branch_id',data.branch_id)
    bodyFormData.append('lead_ad_id', data.lead_ad_id);
    bodyFormData.append('course_category_id',data.course_category_id||1)

      for (var pair of bodyFormData.entries()) {
      }
      axios({
        method: "post",
        url: baseUrl + "student-registration",
        data: bodyFormData
      })
    
    .then(res => {
        dispatch({
            type: 'STEP_ONE_SUCSSESS',
            payload: res
        })
        return res.data;
    })
    .catch( err => {
        dispatch({
            type: 'STEP_ONE_ERR',
            payload: err
        })
        if(err.response) {
        }
    })
}


export const stepTow = (data) => dispatch => {

    let bodyFormData = new FormData();

    bodyFormData.append('course_type',data.course_type)
    bodyFormData.append('lead_id',data.lead_id)

      for (var pair of bodyFormData.entries()) {
      }
      axios({
        method: "post",
        url: baseUrl + "student-course-type",
        data: bodyFormData
      })
    
    .then(res => {
        dispatch({
            type: 'STEP_TOW_SUCSSESS',
            payload: res
        })
    })
    .catch( err => {
        dispatch({
            type: 'STEP_TOW_ERR',
            payload: err
        })
        if(err.response) {
        }
    })
}

export const reset = () => dispatch => {
  dispatch({
    type: 'STEP_TOW_SUCSSESS',
    payload: {}
})
dispatch({
  type: 'STEP_THREE_SUCSSESS',
  payload: {}
})
dispatch({
  type: 'STEP_ONE_SUCSSESS',
  payload: {}
})
    dispatch({
        type: 'CHILD_REG_SUCCESS',
        payload: {}
    })
}

export const stepThree = (data) => dispatch => {

    let bodyFormData = new FormData();
    bodyFormData.append('email',data.email)
    bodyFormData.append('university',data.university)
    bodyFormData.append('faculty',data.faculty)
    bodyFormData.append('address',data.address)
    bodyFormData.append('national_id',data.national_id)
    bodyFormData.append('lead_id',data.lead_id)

      for (var pair of bodyFormData.entries()) {
      }
      axios({
        method: "post",
        url: baseUrl + "student-info",
        data: bodyFormData
      })
    
    .then(res => {
        dispatch({
            type: 'STEP_THREE_SUCSSESS',
            payload: res
        })
    })
    .catch( err => {
        dispatch({
            type: 'STEP_THREE_ERR',
            payload: err
        })
        if(err.response) {
        }
    })
}

export const childReg = (data) => dispatch => {

    let bodyFormData = new FormData();
    bodyFormData.append('name',data.fullName)
    bodyFormData.append('phone',data.mobile)
    bodyFormData.append('mobile',data.mobile)
    bodyFormData.append('branch_id',data.branch_id)
    bodyFormData.append('lead_ad_id', data.lead_ad_id);
    bodyFormData.append('course_category_id',6)
    bodyFormData.append('child_name',data.child_name)
    bodyFormData.append('child_age',data.child_age)
    bodyFormData.append('type', 'Child')

    for (var pair of bodyFormData.entries()) {
    }
    axios({
        method: "post",
        url: baseUrl + "student-registration",
        data: bodyFormData
    })

        .then(res => {
            dispatch({
                type: 'CHILD_REG_SUCCESS',
                payload: res
            })
            return res.data;
        })
        .catch( err => {
            dispatch({
                type: 'STEP_ONE_ERR',
                payload: err
            })
            if(err.response) {
            }
        })
}
