import slider_st from "../../image/slider_st.jpg";
import teacher from "../../image/teacher.jpg";
import { Navgator, SignForCourse, Chat } from "./navgator";
import {SliderPrevStudentsApi} from '../../store/actions/gallery'
import {imageUrl} from '../../urls/urls'
import {connect} from 'react-redux'

import React, { Component } from "react";
import ReactDOM from "react-dom";
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from "react-alice-carousel";



const storageLang = localStorage.getItem('langLocalcStorage')
var finalLang = storageLang == null ? 'en' : storageLang

class PrevStudents extends React.Component {


  componentDidMount = () => {
    this.props.SliderPrevStudentsApi(finalLang)
    window.scrollTo(0, 0);
  }

  onSlideChange(e) {
  }

  onSlideChanged(e) {
  }



  render() {
    const responsive = {
      0: {
        items: 1
      },
      600: {
        items: 2
      }
    };
    const {SliderPrevStudentsData} = this.props

    return (
      <div>
        <div className="row PrevStudents">
          <AliceCarousel
            duration={400}
            autoPlay={true}
            startIndex={1}
            fadeOutAnimation={true}
            mouseDragEnabled={true}
            //playButtonEnabled={true}
            buttonsDisabled={true}
            responsive={responsive}
            autoPlayInterval={2000}
            autoPlayDirection="rtl"
            autoPlayActionDisabled={true}
            onSlideChange={this.onSlideChange}
            onSlideChanged={this.onSlideChanged}
          >
              {SliderPrevStudentsData.map((slide, index ) => {
                  return (
                        <div className="px-4" key={index}>
                          <img
                            src={imageUrl+slide.image}
                            className="img-fluid "
                            alt="img"
                          />
                        </div>
                  );
                })
              }
            {/* <div className="px-4">
              <img src={slider_st} className="img-fluid " alt="" />
            </div>
            <div className="px-4">
              <img src={teacher} className="img-fluid " alt="" />
            </div>
            <div className="px-4">
              <img src={slider_st} className="img-fluid " alt="" />
            </div>
            <div className="px-4">
              <img src={teacher} className="img-fluid " alt="" />
            </div>
            <div className="px-4">
              <img src={slider_st} className="img-fluid " alt="" />
            </div>
            <div className="px-4">
              <img src={teacher} className="img-fluid " alt="" />
            </div>
            <div className="px-4">
              <img src={slider_st} className="img-fluid " alt="" />
            </div>
            <div className="px-4">
              <img src={teacher} className="img-fluid " alt="" />
            </div> */}
          </AliceCarousel>
        </div>
        <div className="row mt-4">
          <Navgator prev="Question" next="" setNav={this.props.setItem} />
        </div>
      </div>
    );
  }
}


const mapStateToProp = state => {
  return {
    SliderPrevStudentsData: state.SliderPrevStudentsData
  };
};

const mapDispatchToProps = dispatch => {
  return{
    SliderPrevStudentsApi : (lang) => dispatch(SliderPrevStudentsApi(lang)),
  }
}

export default   connect(mapStateToProp, mapDispatchToProps )(PrevStudents)