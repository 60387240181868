import { baseUrl, baseApi } from "../../urls/urls.js";
import axios from "axios";

export const courseCategoriesApi = lang => dispatch => {
  fetch(baseUrl + `course-categories?local=${lang}`, {
    method: "GET"
  })
    .then(res => {
      if (res.ok) return res.json();
    })
    .then(coursesCategories =>
      dispatch({
        type: "COURSES_CATEGORIES_SUCCESS",
        payload: coursesCategories
      })
    )
    .catch(err => {
      dispatch({ type: "COURSES_CATEGORIES_SUCCESS_ERR" });
    });
};

export const coursesApi = lang => dispatch => {
  fetch(baseUrl + `courses?local=${lang}`, {
    method: "GET"
  })
    .then(res => {
      if (res.ok) return res.json();
    })
    .then(courses =>
      dispatch({
        type: "COURSES_SUCCESS",
        payload: courses
      })
    )
    .catch(err => {
      dispatch({ type: "COURSES_SUCCESS_ERR" });
    });
};

export const course_id_Api = course_id => dispatch => {
  fetch(baseUrl + `courses/${course_id}?local=en`, {
    method: "GET"
  })
    .then(res => {
      if (res.ok) return res.json();
    })
    .then(categoryById =>
      dispatch({
        type: "COURSE_BY_ID_SUCCESS",
        payload: categoryById
      })
    )
    .catch(err => {
      dispatch({ type: "COURSE_BY_ID_ERR" });
    });
};

export const registerOnlineCourse = (course) => dispatch => {
  dispatch({
    type: "REGISTER_ONLINE_COURSE",
    payload: course
  });
};

export const defaultONlineCourse = () => dispatch => {
  dispatch({
    type: "REGISTER_ONLINE_COURSE",
    payload: null
  });
};
export const onlineCoursesApi = () => dispatch => {
  fetch(baseApi + `get-online-courses`, {
    method: "GET"
  })
    .then(res => {
      if (res.ok) return res.json();
    })
    .then(courses =>
      dispatch({
        type: "ONLINE_COURSES_SUCCESS",
        payload: courses
      })
    )
    .catch(err => {
      dispatch({ type: "COURSES_SUCCESS_ERR" });
    });
};

export const RegOnlineCourse = data => dispatch => {
  dispatch({
    type: "WAIT_REG_COURSE"
  });
  return axios.post(baseApi + "reg-online-courses", data, {
    headers: {
      Accept: "application/json"
    }
  });
};
export const defaultWait = () => dispatch => {
  dispatch({
    type: "DEFAULT_WAIT_REG_COURSE"
  });
};
