import { baseUrl } from '../../urls/urls'
import axios from 'axios'

export const coummuntiyApi = () => dispatch => {
    axios.get(baseUrl+`get-count`)
    .then( get_count => {
        dispatch({
            type: 'Get_COUNT_SUCSSESS',
            payload: get_count.data
        })
    })
    .catch(err => {
        dispatch({
            type: 'Get_COUNT_ERR', 
            payload: err
        })
        if(err.response) {
        }
    })
} 

