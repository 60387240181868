import React, { Component } from "react";
import { Translation } from "react-i18next";
import Swal from "sweetalert2";
import { stepTow } from "../../store/actions/studentRegApi";
import { connect } from "react-redux";
import StudentStepThree from "./StudentStepThree";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: toast => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  }
});

class StudentStepTow extends Component {
  state = {
    course_type: "",
    lead_id: this.props.studentStepOne.data.lead_id,
    sec: "Tow"
  };

  componentDidUpdate() {
    if (this.props.studentStepTow.data) {
      Toast.fire({
        icon: this.props.studentStepTow.data.type,
        title: this.props.studentStepTow.data.message
      });
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const { course_type } = this.state;
    if (!course_type) {
      Toast.fire({
        icon: "error",
        title: "Please Select Course Type"
      });
    } else {
      this.props.stepTow(this.state);
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.studentStepTow.status === 200) {
      this.setState({ sec: "Three" });
    }
  }

  handleRadio = e => {
    this.setState({
      course_type: e.target.value
    });
  };

  render() {
    return (
      <div>
        {this.state.sec === "Tow" ? (
          <form
            onSubmit={this.handleSubmit}
            noValidate
            autoComplete="off"
            className="my-3"
          >
            <div className="row mb-4">
              <div className="col-md-4 mx-auto">
                <span className="item-steps  d-inline-block">
                  <h6 className="text-center "> 3 </h6>
                </span>
                <span className="item-steps bg-info d-inline-block">
                  <h6 className="text-center "> 2 </h6>
                </span>
                <span className="item-steps bg-info d-inline-block">
                  <h6 className="text-center "> 1 </h6>
                </span>
              </div>
            </div>

            <div className="py-3 col-md-10 mx-auto">
              <div className="top-desc p-3 rounded">
                <Translation>
                  {t => (
                    <p className="text-center py-0 my-0 text-white">
                      {t("student_note")}
                    </p>
                  )}
                </Translation>
              </div>
              <div className="row mx-0 px-0">
                <div className="col-md-3 ">
                  <div className="form-check form-check-inline z-radio">
                    <input
                      className="form-check-input position-absolute text-center"
                      type="radio"
                      name="course_type"
                      id="Bronze"
                      value="Bronze Package"
                      checked={this.state.course_type === "Bronze Package"}
                      onChange={this.handleRadio}
                    />
                    <label
                      className="item border rounded text-center px-3 py-4 bg-white"
                      htmlFor="Bronze"
                    >
                      <Translation>
                        {t => (
                          <h6 className="font-weight-bold text-center">
                            {t("package_one")}
                          </h6>
                        )}
                      </Translation>
                      <Translation>
                        {t => (
                          <small className="d-block text-center">
                            {t("package_one_desc")}
                          </small>
                        )}
                      </Translation>
                    </label>
                    <br />
                  </div>
                </div>
                <div className="col-md-3 ">
                  <div className="form-check form-check-inline z-radio">
                    <input
                      className="form-check-input position-absolute text-center"
                      type="radio"
                      id="Silver"
                      name="course_type"
                      value="Silver Package"
                      checked={
                        this.state.course_type === "Silver Package"
                      }
                      onChange={this.handleRadio}
                    />
                    <label
                      className="item border rounded text-center px-3 py-4 bg-white text-center"
                      htmlFor="Silver"
                    >
                      <Translation>
                        {t => (
                          <h6 className="font-weight-bold text-center">
                            {t("package_tow")}
                          </h6>
                        )}
                      </Translation>
                      <Translation>
                        {t => (
                          <small className="d-block text-center">
                            {t("package_tow_desc")}
                          </small>
                        )}
                      </Translation>
                    </label>
                    <br />
                  </div>
                </div>

                <div className="col-md-3 ">
                  <div className="form-check form-check-inline z-radio">
                    <input
                      className="form-check-input position-absolute text-center"
                      type="radio"
                      id="Gold"
                      name="course_type"
                      value="Gold Package"
                      checked={
                        this.state.course_type ===
                        "Gold Package"
                      }
                      onChange={this.handleRadio}
                    />
                    <label
                      className="item border rounded text-center px-3 py-4 bg-white text-center"
                      htmlFor="Gold"
                    >
                      <Translation>
                        {t => (
                          <h6 className="font-weight-bold text-center">
                            {t("package_three")}
                          </h6>
                        )}
                      </Translation>
                      <Translation>
                        {t => (
                          <small className="d-block text-center">
                            {t("package_three_desc")}
                          </small>
                        )}
                      </Translation>
                    </label>
                    <br />
                  </div>
                </div>
                <div className="col-md-3 ">
                  <div className="form-check form-check-inline z-radio">
                    <input
                        className="form-check-input position-absolute text-center"
                        type="radio"
                        id="Platinum"
                        name="course_type"
                        value="Platinum Package"
                        checked={
                            this.state.course_type ===
                            "Platinum Package"
                        }
                        onChange={this.handleRadio}
                    />
                    <label
                        className="item border rounded text-center px-3 py-4 bg-white text-center"
                        htmlFor="Platinum"
                    >
                      <Translation>
                        {t => (
                            <h6 className="font-weight-bold text-center">
                              {t("package_four")}
                            </h6>
                        )}
                      </Translation>
                      <Translation>
                        {t => (
                            <small className="d-block text-center">
                              {t("package_four_desc")}
                            </small>
                        )}
                      </Translation>
                    </label>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 ml-auto">
                <Translation>
                  {t => (
                    <button className="btn btn-info btn-block btn-lg mt-4">
                      {" "}
                      {t("button_Next")}{" "}
                    </button>
                  )}
                </Translation>
              </div>
            </div>
          </form>
        ) : (
          this.state.sec === "Three" && <StudentStepThree />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    studentStepOne: state.studentStepOne,
    studentStepTow: state.studentStepTow
  };
};

const mapDispatchToProps = dispatch => {
  return {
    stepTow: data => dispatch(stepTow(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentStepTow);
