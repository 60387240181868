import React, { Component } from "react";
import "./JoinUs.scss";
import { Translation } from "react-i18next";
import { connect } from "react-redux";
import join_0 from "../../../image/join_0.png";
import join_1 from "../../../image/join_1.png";
import join_2 from "../../../image/join_2.png";
import join_3 from "../../../image/join_3.png";
import join_4 from "../../../image/join_4.png";

class joinUs extends Component {
  render() {
    return (
      <div className="joinUs">
        <div className="container mt-md-3 pt-md-5 ">
          <Translation>
            {t => (
              <h1
                className="text-center"
                data-aos="zoom-in"
                data-aos-duration="3000"
              >
                {t("Why_Join_Us")}
              </h1>
            )}
          </Translation>
          <div className="row">
            <div
              className="col-md-10 mx-auto"
              data-aos="zoom-in"
              data-aos-duration="2400"
            >
              <Translation>
                {t => <p className="text-center">{t("Join_Desc")}</p>}
              </Translation>
            </div>
          </div>
          <div className="row mt-5">
            {/* item */}
            <div className="col-md col-12">
              <div
                className="join-item mb-3 p-3  pb-5 "
                data-aos="fade-left"
                data-aos-duration="4000"
              >
                <img
                  src={join_1}
                  className="img-fluid d-block mx-auto mb-4 "
                  alt="img"
                />
                <div className="heading-product">
                  <Translation>
                    {t => (
                      <h4 className="h6 text-center text-dark mb-0 pb-0">
                        {t("card_one")}
                      </h4>
                    )}
                  </Translation>
                  <Translation>
                    {t => (
                      <small className="text-center d-inline-block text-muted mt-2 ">
                        {t("card_one_desc")}
                      </small>
                    )}
                  </Translation>
                </div>
              </div>
            </div>
            {/* item */}
            <div className="col-md col-12">
              <div
                className="join-item mb-3 p-3  pb-5 "
                data-aos="fade-right"
                data-aos-duration="6000"
              >
                <img
                  src={join_2}
                  className="img-fluid d-block mx-auto mb-4 "
                  alt="img"
                />
                <div className="heading-product">
                  <Translation>
                    {t => (
                      <h4 className="h6 text-center text-dark mb-0 pb-0">
                        {t("card_tow")}
                      </h4>
                    )}
                  </Translation>
                  <Translation>
                    {t => (
                      <small className="text-center d-inline-block text-muted mt-2 ">
                        {t("card_tow_desc")}
                      </small>
                    )}
                  </Translation>
                </div>
              </div>
            </div>
            {/* item */}
            <div className="col-md col-12">
              <div
                className="join-item mb-3 p-3  pb-5 "
                data-aos="fade-left"
                data-aos-duration="8000"
              >
                <img
                  src={join_3}
                  className="img-fluid d-block mx-auto mb-4 "
                  alt="img"
                />
                <div className="heading-product">
                  <Translation>
                    {t => (
                      <h4 className="h6 text-center text-dark mb-0 pb-0">
                        {t("card_three")}
                      </h4>
                    )}
                  </Translation>
                  <Translation>
                    {t => (
                      <small className="text-center d-inline-block text-muted mt-2 ">
                        {t("card_three_desc")}
                      </small>
                    )}
                  </Translation>
                </div>
              </div>
            </div>
            {/* item */}
            <div className="col-md col-12">
              <div
                className="join-item mb-3 p-3  pb-5 "
                data-aos="fade-right"
                data-aos-duration="10000"
              >
                <img
                  src={join_4}
                  className="img-fluid d-block mx-auto mb-4 "
                  alt="img"
                />
                <div className="heading-product">
                  <Translation>
                    {t => (
                      <h4 className="h6 text-center text-dark mb-0 pb-0">
                        {t("card_four")}
                      </h4>
                    )}
                  </Translation>
                  <Translation>
                    {t => (
                      <small className="text-center d-inline-block text-muted mt-2 ">
                        {t("card_four_desc")}
                      </small>
                    )}
                  </Translation>
                </div>
              </div>
            </div>
            {/* item */}
            <div className="col-md col-12">
              <div
                className="join-item mb-3 p-3  pb-5 "
                data-aos="fade-right"
                data-aos-duration="2000"
              >
                <img
                  src={join_0}
                  className="img-fluid d-block mx-auto mb-4 "
                  alt="img"
                />
                <div className="heading-product">
                  <Translation>
                    {t => (
                      <h4 className="h6 text-center text-dark mb-0 pb-0">
                        {t("card_five")}
                      </h4>
                    )}
                  </Translation>
                  <Translation>
                    {t => (
                      <small className="text-center d-inline-block text-muted mt-2 ">
                        {t("card_five_desc")}
                      </small>
                    )}
                  </Translation>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    category: state.category
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // name: data => dispatch(name(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(joinUs);
