import React from "react";
import { Link } from "react-router-dom";
import placeholder from "../../../image/placeholder.png";
import { imageUrl } from "../../../urls/urls";

const coursesSummary = ({ data }) => {
  return (
    <div className="col-md-4">
      <Link to={"/course-details/" + data.id}>
        <div
          className="Coursers-item pb-3 mb-4"
          data-aos="flip-up"
          data-aos-duration="6000"
        >
          {data.image ? (
            <img
              src={imageUrl + data.image}
              className="img-fluid w-100"
              alt=""
            />
          ) : (
            <img src={placeholder} className="img-fluid w-100" alt="" />
          )}
          <p className="price"> {data ? data.cost : null}LE </p>
          <h6 className="mt-3 px-3 text-dark"> {data ? data.name : null} </h6>
          <small className="d-block px-3 text-muted">
            {" "}
            {data ? data.description : null}{" "}
          </small>
        </div>
      </Link>
    </div>
  );
};

export default coursesSummary;
