import { baseUrl } from '../../urls/urls'
import axios from 'axios'

export const contactUs = (data) => dispatch => {
    axios.post(baseUrl+'contacts', {
        "name": data.name,
        "phone": data.phone,
        "message": data.message
    })
    .then(res => {
        dispatch({
            type : 'CONTACT_US_SECCESS',
            payload : res,
        })
    })
    .catch( err => {
        dispatch({
            type : 'CONTACT_US_ERROR',
            payload : err.response.data
        })
        if (err.response) {
        }
  })
}