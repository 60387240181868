import React, { Component } from "react";
import './galleryPage.scss'
import {galleryApi} from '../../store/actions/gallery'
import {connect} from 'react-redux'
import {imageUrl} from '../../urls/urls'
import Graduation from '../../image/Graduation.svg'
import placeholder from '../../image/placeholder.png'
import { Translation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';

const storageLang = localStorage.getItem('langLocalcStorage')
var finalLang = storageLang == null ? 'en' : storageLang

// var finalLang = storageLang == null ? navigator.language : storageLang

class GalleryPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    })
      this.props.galleryApi(finalLang)
  }
  

  render() {
    const {gallery_info} = this.props
    return (
      <div className="GalleryPage py-5">
        <div className="container">

        <div className="header-photo-gallery ">
            <Translation>
                {t => (
                    <h1 className="text-center" data-aos='zoom-in' data-aos-duration="3000"> 
                      {t("Photo_Gallery")}
                    </h1>
                )}
            </Translation>
            <div className="row">
              <div className="col-md-10 mx-auto">
                <Translation>
                  {t => (
                    <p className="text-center" data-aos='zoom-in' data-aos-duration="3000">
                        {t("Gallery_desc")}
                    </p>
                  )}
                </Translation>
              </div>
            </div>
          </div>

          <div className="photos mt-5 pt-5">
            <div className="row">
              {gallery_info ? gallery_info.map((item,index) => {
                return(
                  <div 
                    key={item.id}
                    className={ 
                        index === 0 ?  "col-md-4 col-6" : 
                        index === 1 ?  "col-md-5 col-6" :
                        index === 2 ?  "col-md-3 col-6" : 

                        index === 3 ?  "col-md-3 col-6" : 
                        index === 4 ?  "col-md-4 col-6" : 
                        index === 5 ?  "col-md-5 col-6" : 

                        index === 6 ?  "col-md-4 col-6" : 
                        index === 7 ?  "col-md-5 col-6" : 
                        index === 8 ?  "col-md-3 col-6" :

                        index === 9 ?  "col-md-3 col-6" :
                        index === 10 ? "col-md-4 col-6" :
                        index === 11 ? "col-md-5 col-6" :
                        index > 11 && "col-md-4 col-6" 
                      }
                    >
                    <img src={item.image ? imageUrl+item.image : placeholder} className="img-fluid w-100 mb-4" alt="real soft" data-aos='fade-up-left' data-aos-duration="6000" />
                  </div>
                )
              }) : null }

                {/* <div className="col-md-12">
                <div className="path-bottom-gallety rounded p-4 mt-4" data-aos='fade-up-right' data-aos-duration="6000">
                    <div className="row">
                        <div className="col-6">
                          <Translation>
                            {t => (
                              <h1 className="h3 text-white">
                                {t("Gallery_card_title")}
                              </h1>
                            )}
                          </Translation>
                          <Translation>
                            {t => (
                              <p className="text-white" > 
                                {t("Gallery_card_desc")}
                              </p>
                            )}
                          </Translation>
                        </div>
                        <div className="col-md-3 ml-auto">
                            <img src={Graduation} className="img-fluid" alt="Graduation"/>
                        </div>
                    </div>
                </div>
                </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    gallery_info : state.gallery
  }
}

const mapDispatchToProps = dispatch => {
  return {
    galleryApi: (lang) => dispatch(galleryApi(lang))
  };
};

export default connect(mapStateToProps, mapDispatchToProps )(GalleryPage);
