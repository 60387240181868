import React, { Component } from "react";
import "./footer.scss";
import twitter from "../../../image/twitter.svg";
import instagram from "../../../image/instagram.svg";
import facebook from "../../../image/facebook.svg";
import youtube from "../../../image/youtube.svg";
import logo_majal from "../../../image/logo_majal.png";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import "aos/dist/aos.css";

export default class Footer extends Component {

  render() {
    return (
      <div className="footer pt-5 mt-5">
        <div className="container">
          <div className="row border-bottom pb-4 ">
            <div className="col-md-6 col-6 border-right text-center ">
              <div className="site-map mt-md-0 mt-3">
                <Translation>
                  {t => (
                    <h5
                    className="text-white text-center"
                  >
                      {t("site_map")}
                  </h5>
                  )}
                </Translation>
                <ul
                  className="list-unstyled mt-3 text-md-center pr-0 "
                >
                  <li className="list-inline-item">
                    <Translation>
                      {t => (
                        <Link to="/" className="mx-2">
                          {t("home_menu")}
                        </Link>
                      )}
                    </Translation>
                  </li>
                  <li className="list-inline-item">
                    <Translation>
                      {t => (
                        <Link to="/about-us" className="mx-2">
                          {t("about_menu")}
                        </Link>
                      )}
                    </Translation>
                  </li>
                  <li className="list-inline-item">
                    <Translation>
                      {t => (
                        <Link to="/courses" className="mx-2">
                          {t("courses_menu")}
                        </Link>
                      )}
                    </Translation>
                  </li>
                  <li className="list-inline-item">
                    <Translation>
                      {t => (
                        <Link to="/gallery" className="mx-2">
                          {t("gallery_menu")}
                        </Link>
                      )}
                    </Translation>
                  </li>
                  <li className="list-inline-item">
                    <Translation>
                      {t => (
                        <Link to="/branches" className="mx-2">
                          {t("branches_menu")}
                        </Link>
                      )}
                    </Translation>
                  </li>
                  <li className="list-inline-item">
                    <Translation>
                      {t => (
                        <Link to="/jobs" className="mx-2">
                          {t("jobs_menu")}
                        </Link>
                      )}
                    </Translation>
                  </li>
                  <li className="list-inline-item">
                    <Translation>
                      {t => (
                        <Link to="/student-registration" className="mx-2">
                          {t("student_menu")}
                        </Link>
                      )}
                    </Translation>
                  </li>
                  <li className="list-inline-item">
                    <Translation>
                      {t => (
                        <Link to="/contact-us" className="mx-2">
                          {t("contact_menu")}
                        </Link>
                      )}
                    </Translation>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-6 col-6 ">
              <div className="social text-center mt-md-0 mt-3">
                  <Translation>
                    {t => (
                       <h5
                       className="text-white text-center"
                     >
                       {t("Social_Media")}
                     </h5>
                    )}
                  </Translation>
               
                <ul
                  className="list-inline mt-4 text-center pr-0"
                >
                  <li className="list-inline-item">
                    <a href="https://www.instagram.com/real_soft_house/" target="_blank">
                      <img src={instagram} className="img-fluid" alt="img" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://www.facebook.com/REALSOFTHOUSE/" target="_blank">
                      <img src={facebook} className="img-fluid" alt="img" />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://www.youtube.com/channel/UCKo2iiacnNxiD98yY0wJ4AQ" target="_blank">
                      <img src={youtube} className="img-fluid" alt="img" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright bg-white ">
          <div className="container ">
                  <Translation>
                    {t => (
                    <small className="text-muted copy-right d-block mx-auto text-center p-3 ">
                       {t("copyright")}
                     {" "}
                      <a href="https://majallab.com" className="" target="_blank" rel="noopener noreferrer">
                        Majal Lab{" "}
                      </a>
                    </small>
                    )}
                  </Translation>
           
          </div>
        </div>
      </div>
    );
  }
}
