import React, { Component } from "react";
import "./contactUs..scss";
import { contactUs } from "../../store/actions/contactUs";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import imgage from "../../image/photo_r3.jpg";
import title_branch from "../../image/title.svg";
import phone from "../../image/phone.svg";
import calendar from "../../image/calendar.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Translation } from "react-i18next";
import Swal from 'sweetalert2'

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })



class ContactUs extends Component {
  componentDidMount() {
    AOS.init({
      duration: 2000
    });
  }

  state = {
    name: "",
    phone: "",
    message: ""
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handlesubmit = e => {
    e.preventDefault();
    const { name, phone, message } = this.state;
    const regex = /01[0-2|5]{1}[0-9]{8}/
    if (!name) {
      Toast.fire({
        icon: 'error',
        title: 'Please Enter Your Name'
      })
    }
    else if (!regex.test(phone)) {
      Toast.fire({
        icon: 'error',
        title: 'Please Enter valid phone number'
      })
    }  else if(!message) {
      Toast.fire({
        icon: 'error',
        title: 'Please Enter your message'
      })
    } else {
      this.props.contactUs(this.state);
      if(this.props.contact_Us_res.status === 200) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Your message has been successfully received',
          showConfirmButton: false,
          timer: 1500
        })
        this.setState({
          name: "",
          phone: "",
          message: ""
        })
        this.props.Done()
      }

    }
  };

  render() {
    return (
      <div className="ContactUs py-5">
        <div className="container">
          <div className="header-photo-gallery ">
            <Translation>
              {t => (
                <h1
                  className="text-center"
                  data-aos="zoom-in"
                  data-aos-duration="3000"
                >
                  {t("contactUS_title")}
                </h1>
              )}
            </Translation>

            <div className="row">
              <div className="col-md-10 mx-auto">
                <Translation>
                  {t => (
                    <p
                      className="text-center"
                      data-aos="zoom-in"
                      data-aos-duration="3000"
                    >
                      {t("contactUS_desc")}
                    </p>
                  )}
                </Translation>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-md-8">
              <div className="contact-form border rounded p-4 py-5 mb-md-0 mb-4">
                <form onSubmit={this.handlesubmit} >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <Translation>
                          {t => (
                            <input
                              value={this.state.name}
                              onChange={this.handleChange}
                              type="text"
                              
                              id="name"
                              className="form-control form-control-lg"
                              placeholder={t("placeholder_name")}
                              required
                            />
                          )}
                        </Translation>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <Translation>
                          {t => (
                            <input
                              value={this.state.phone}
                              onChange={this.handleChange}
                              type="tel"
                              pattern="^01[0-2|5]{1}[0-9]{8}"
                              maxLength="11"
                              id="phone"
                              className="form-control form-control-lg"
                              placeholder={t("placeholder_phone")}
                            />
                          )}
                        </Translation>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <Translation>
                      {t => (
                        <textarea
                          cols="30"
                          rows="6"
                          value={this.state.message}
                          onChange={this.handleChange}
                          id="message"
                          className="form-control form-control-lg"
                          placeholder={t("placeholder_message")}
                        ></textarea>
                      )}
                    </Translation>
                  </div>
                  <div className="row mt-5 ">
                    <div className="col-md-9">
                      <Translation>
                        {t => <p>{t("text_from_contact")}</p>}
                      </Translation>
                    </div>
                    <div className="col-md-3">
                      <Translation>
                        {t => (
                          <button className="btn btn-info btn-block btn-lg ">
                            {" "}
                            {t("button_sent")}{" "}
                          </button>
                        )}
                      </Translation>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-4">
              <Link to="/jobs">
                <div
                  className="Coursers-item-path hvr-bob w-100 mb-4"
                  data-aos-duration="3000"
                >
                  <div className="desc-res-item">
                    <Translation>
                      {t => <h4> {t("TeacherRegistration")} </h4>}
                    </Translation>
                    <Translation>
                      {t => <p className="">{t("TeacherRegistration_desc")}</p>}
                    </Translation>
                  </div>
                </div>
              </Link>
              <Link to="/student-registration" className="mt-4 d-block">
                <div
                  className="Coursers-item-path Coursers-item-path-student hvr-bob w-100 mb-4"
                  data-aos-duration="3000"
                >
                  <div className="desc-res-item">
                    <Translation>
                      {t => <h4> {t("StudentRegistration")} </h4>}
                    </Translation>
                    <Translation>
                      {t => <p className="">{t("StudentRegistration_desc")}</p>}
                    </Translation>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contact_Us_res: state.contact_Us_res
  };
};

const mapDispatchToProps = dispatch => {
  return {
    contactUs: data => dispatch(contactUs(data)),
    Done: () => {
      dispatch({
        type: "CONTACT_US_SECCESS",
        payload: null
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
