import { baseUrl } from '../../urls/urls.js'
import axios from 'axios'

export const brancheApi = (lang) => dispatch => {
    axios.get(baseUrl+`branches?local=en`)
    .then(branche => {
        dispatch({
            type : 'BRANCHE_SUCCESS',
            payload : branche.data
        })
    })
    .catch( err => {
        dispatch({
            type : 'BRANCHE_ERR',
            payload : err.response
        })
        if (err.response) {
        }
  })
}
