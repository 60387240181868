import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {connect} from 'react-redux'
import {coursesApi} from '../../../store/actions/Courses'
import Polygon_back from '../../../image/Polygon_back.svg' 
import Polygon_front from '../../../image/Polygon_front.svg' 
import './coursers.scss'
import CoursesSummary from './CoursesSummary';
import { Translation } from "react-i18next";


const storageLang = localStorage.getItem('langLocalcStorage')
var finalLang = storageLang == null ? 'en' : storageLang


class Coursers extends Component {

    componentDidMount = () => {
        this.props.coursesApi(finalLang)
    }

    render() {
        const { coursesData } = this.props
        return (
            <div className="Coursers py-5 mt-5" >
                <div className="container">
                    <div className="row">
                        <div className="col-md-4"  data-aos='zoom-in' data-aos-duration="3000">
                            <Translation>
                                {t => (
                                    <h1 className="mb-0 pb-0 text-center text-md-left"> 
                                        {t("course_heading")}
                                    </h1>
                                )}
                            </Translation>
                            <Translation>
                                {t => (
                                    <small className="d-block text-center text-md-left"> 
                                        {t("course_desc")}
                                    </small>
                                )}
                            </Translation>
                        </div>
                        <div className="col-md-3 ml-auto" data-aos='zoom-in' data-aos-duration="3000">
                            <Translation>
                                {t => (
                                     <Link to="/courses" className="btn btn-info d-inline-block btn-block rounded-pill btn-lg mt-3" >
                                        {t("view_all")}
                                    </Link>
                                   
                                )}
                            </Translation>
                        </div>
                    </div>
                    <div className="row mt-5">
                        {coursesData ? coursesData.slice(0,6).map(course => {
                                return(
                                    <CoursesSummary
                                        id={course.id}
                                        key={course.id}
                                        data={course}
                                    />
                                )
                            }) :  null}
                        {/* item-path */}
                        {/* <div className="col-md-4">
                            <Link to="/" className="" >
                            <div className="Coursers-item-path w-100 mb-4" data-aos='fade-left' data-aos-duration="6000" data-aos-delay="300">
                                <div className="desc-res-item">
                                    <h4> Register now and get a discount </h4>
                                    <h3> 50% </h3>
                                </div>
                            </div>
                            </Link>
                        </div> */}
                    </div>
                </div>
                <div className="Polygon_back" data-aos='fade-left' data-aos-duration="6000" data-aos-delay="3500">
                    <img src={Polygon_back} className="img-fluid"  alt="Polygon_back"/>
                </div>
                <div className="Polygon_front" data-aos='fade-right' data-aos-duration="6000" data-aos-delay="6300" >
                    <img src={Polygon_front} className="img-fluid"  alt="Polygon_front"/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        coursesData : state.coursesData,

    }
}


const mapDispatchToProps = dispatch => {
    return{
        coursesApi : (lang) => dispatch(coursesApi(lang)),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Coursers)