
import { baseUrl } from '../../urls/urls'
import axios from 'axios'

export const galleryApi = (lang) => dispatch => {
    axios.get(baseUrl+`assets-by-category?asset_category=Gallery&local=en`)
    .then( galleryData => {
        dispatch({
            type: 'GALLER_DATA_SUCSSESS',
            payload: galleryData.data
        })
    })
    .catch(err => {
        dispatch({
            type: 'GALLER_DATA_ERR', 
            payload: err
        })
        if(err.response) {
        }
    })
}

export const onlineSliderApi = (lang) => dispatch => {
    axios.get(baseUrl+`assets-by-category?asset_category=Online Slider&local=en`)
        .then( slider_onlineReg => {
            dispatch({
                type: 'SLIDER_ONLINE_REG_SUCSSESS',
                payload: slider_onlineReg.data
            })
        })
        .catch(err => {
            dispatch({
                type: 'SLIDER_ONLINE_REG_ERR',
                payload: err
            })
            if(err.response) {
            }
        })
}

export const onlineOldPatchesApi = (lang) => dispatch => {
    axios.get(baseUrl+`assets-by-category?asset_category=Old Patches&local=en`)
        .then( galleryData => {
            dispatch({
                type: 'GALLER_DATA_SUCSSESS',
                payload: galleryData.data
            })
        })
        .catch(err => {
            dispatch({
                type: 'GALLER_DATA_ERR',
                payload: err
            })
            if(err.response) {
            }
        })
}

export const systemGalleryApi = (lang) => dispatch => {
    axios.get(baseUrl+`assets-by-category?asset_category=Gallery&local=en`)
    .then( galleryData => {
        dispatch({
            type: 'GALLER_DATA_SUCSSESS',
            payload: galleryData.data
        })
    })
    .catch(err => {
        dispatch({
            type: 'GALLER_DATA_ERR', 
            payload: err
        })
        if(err.response) {
        }
    })
} 
export const SliderPrevStudentsApi = (lang) => dispatch => {
    axios.get(baseUrl+`assets-by-category?asset_category=Old%20Patches&local=en`)
    .then( SliderPrevStudents => {
        dispatch({
            type: 'SLIDER_PERV_STUDENT_SUCSSESS',
            payload: SliderPrevStudents.data
        })
    })
    .catch(err => {
        dispatch({
            type: 'SLIDER_PERV_STUDENT_ERR', 
            payload: err
        })
        if(err.response) {
        }
    })
} 

