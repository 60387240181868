import { baseUrl } from '../../urls/urls.js'
import axios from 'axios'

export const pixelsApi = (ad_id) => dispatch => {
    axios.get(baseUrl+`pixels?fb_lead_ad_id=${ad_id}`)
    .then(pixelsRes => {
        dispatch({
            type : 'PIXELS_SUCCESS',
            payload : pixelsRes.data
        })
    })
    .catch( err => {
        dispatch({
            type : 'PIXELS_ERR',
            payload : err.response
        })
        if (err.response) {
        }
  })
}
