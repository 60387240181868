import React from "react";
import { useDispatch } from "react-redux";

export function Navgator({ prev, next, setNav }) {
  return (
    <React.Fragment>
      <div className="col-md-4 my-4 my-md-0">
        <button
          onClick={e => setNav(prev)}
          className="btn btn-outline-danger btn-block mt-2 "
          disabled={prev ? false : true}
        >
            <span style={{marginLeft:1.25 + 'em'}}>&#8680;</span>
          السابق
        </button>
      </div>
        <div className="col-md-4">
            <SignForCourse setNav={setNav} />
        </div>
      <div className="col-md-4 my-4 my-md-0">
        <button
          onClick={e => setNav(next)}
          className="btn btn-outline-danger btn-block mt-2 "
          disabled={next ? false : true}
        >
          التالي
            <span style={{marginRight:1.25 + 'em'}}>&#8678;</span>
        </button>
      </div>
    </React.Fragment>
  );
}

export function SignForCourse({ setNav }) {
  const dispatch = useDispatch();
  return (
    <button
      onClick={e => {
        setNav("Cost");
        dispatch({ type: "SIGN_COURSE" });
      }}
      className="btn btn-info btn-block"
    >
      {" "}
      سجل الان{" "}
    </button>
  );
}

export function Chat() {
  return (
    <button
      onClick={e => {
        window.FB.CustomerChat.hideDialog();
        window.FB.CustomerChat.showDialog();
      }}
      className="btn btn-warning btn-block px-5 mt-3"
    >
      الشات السريع
    </button>
  );
}
