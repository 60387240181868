import React from "react";
import {Translation} from "react-i18next";
import {connect} from "react-redux";
import IntlTelInput from "react-intl-tel-input";
import {RegOnlineCourse, defaultWait} from "../../store/actions/Courses";
import "react-intl-tel-input/dist/main.css";
import Swal from "sweetalert2";
import queryString from "query-string";
import {withRouter} from "react-router-dom";
import ReactGA from 'react-ga';


const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    onOpen: toast => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    }
});

class CourseReg extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            mobile: "",
            nationalNumber: "",
            firstName: "",
            lastName: "",
            iframeSrc: "",
            validMobile: false
        };
    }

    handleChange = e => {
        this.setState({
            [e.target.id]: e.target.value
        });
    };
    handleSubmit = e => {
        e.preventDefault();
        if (!this.state.validMobile) {
            Toast.fire({
                icon: "error",
                title: "Please Enter Valid Phone Number"
            });
            return;
        }
        const {email, firstName, lastName, nationalNumber} = this.state;
        const {onlineCourseId, currency, paymentMethod: payment_method, fb_ad_id: fb_ad_id} = this.props;
        const data = this.props.RegOnlineCourse({
            email,
            mobile: nationalNumber,
            firstName,
            lastName,
            fb_ad_id,
            onlineCourseId,
            currency,
            payment_method
        });
        data
            .then(data => {
                if (data.status == 200) {
                    window.fbq('track', 'SubmitApplication');
                    ReactGA.event({
                        category: 'Student',
                        action: 'Submit Registration Form'
                    });
                    if (data.data.status) {
                        let params = queryString.stringify(data.data);
                        this.props.history.push({search: params});
                        window.location.reload();
                    } else {
                        this.setState({iframeSrc: data.data});
                    }
                } else {
                    Toast.fire({
                        icon: "error",
                        title: "server error"
                    });
                }
                this.props.defaultWait();
            })
            .catch(() => {
                Toast.fire({
                    icon: "error",
                    title: "something went wrong"
                });
                this.props.defaultWait();
            });
    };
    mobile = (validMobile, mobile, nationalNumber) => {
        this.setState({mobile, validMobile, nationalNumber});
    };

    render() {
        const {waitRegCourse, paymentMethod, currency} = this.props;
        return (
            <React.Fragment>
                {this.state.iframeSrc ? (
                    <iframe
                        className=" py-5"
                        src={this.state.iframeSrc}
                        style={{width: "100%", height: "500px"}}
                    ></iframe>
                ) : (
                    <React.Fragment>
                        <div>
                            {(paymentMethod == "Vodafone Cash" ||
                                paymentMethod == "Fawry Pay") && (
                                <div className="PaymentMethod">
                                    <div className="note mt-5" style={{height: "100px"}}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="content-note px-3">
                                                    {paymentMethod == "Vodafone Cash" && (
                                                        <h4 className="text-white text-center">
                                                            قم بتحويل مبلغ الكورس على رقم 01011117222 من خلال
                                                            خدمة فودفون كاش وتواصل معنا
                                                        </h4>
                                                    )}
                                                    {paymentMethod == "Fawry Pay" && (
                                                        <h4 className="text-white text-center">
                                                            قم بدفع مبلغ الكورس على رقم 01011117222 من خلال
                                                            خدمة فوري باي وتواصل معنا
                                                        </h4>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="container py-5">
                            <Translation>
                                {t => (
                                    <h1 className="text-center">{t("Course_Registration")}</h1>
                                )}
                            </Translation>

                            <div className="mx-auto col-md-8 border p-4 mt-5 " dir="ltr">
                                <form
                                    onSubmit={this.handleSubmit}
                                    autoComplete="off"
                                    className="my-3"
                                >
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input
                                                    type="hidden"
                                                    id="fb_ad_id"
                                                    value={this.state.fb_ad_id}
                                                />
                                                <Translation>
                                                    {t => (
                                                        <input
                                                            onChange={this.handleChange}
                                                            type="text"
                                                            placeholder={t("student_first_Name")}
                                                            id="firstName"
                                                            className="form-control form-control-lg"
                                                            value={this.state.firstName}
                                                            required
                                                        />
                                                    )}
                                                </Translation>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <Translation>
                                                    {t => (
                                                        <input
                                                            onChange={this.handleChange}
                                                            type="text"
                                                            placeholder={t("student_last_Name")}
                                                            id="lastName"
                                                            className="form-control form-control-lg"
                                                            value={this.state.lastName}
                                                            required
                                                        />
                                                    )}
                                                </Translation>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <Translation>
                                                    {t => (currency == "USD" ?
                                                            <IntlTelInput
                                                                preferredCountries={["eg"]}
                                                                placeholder={t("student_mobile")}
                                                                onPhoneNumberChange={(valid, phone, obj, nationalNumber) =>
                                                                    this.mobile(valid, phone, nationalNumber.replace(/[\(\)\-\s\+]+/g, ''))
                                                                }
                                                                onPhoneNumberBlur={(valid, phone, obj, nationalNumber) =>
                                                                    this.mobile(valid, phone, nationalNumber.replace(/[\(\)\-\s\+]+/g, ''))
                                                                }
                                                                value={this.state.mobile}
                                                                inputClassName="form-control form-control-lg"
                                                            /> :
                                                            <IntlTelInput
                                                                onlyCountries={["eg"]}
                                                                preferredCountries={["eg"]}
                                                                placeholder={t("student_mobile")}
                                                                onPhoneNumberChange={(valid, phone, obj, nationalNumber) =>
                                                                    this.mobile(valid, phone, nationalNumber.replace(/[\(\)\-\s\+]+/g, ''))
                                                                }
                                                                onPhoneNumberBlur={(valid, phone, obj, nationalNumber) =>
                                                                    this.mobile(valid, phone, nationalNumber.replace(/[\(\)\-\s\+]+/g, ''))
                                                                }
                                                                value={this.state.mobile}
                                                                inputClassName="form-control form-control-lg"
                                                            />
                                                    )}
                                                </Translation>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <Translation>
                                                    {t => (
                                                        <input
                                                            type="email"
                                                            onChange={this.handleChange}
                                                            placeholder={t("Student_Email")}
                                                            id="email"
                                                            className="form-control form-control-lg"
                                                            value={this.state.email}
                                                            required
                                                        />
                                                    )}
                                                </Translation>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 ml-auto">
                                            <Translation>
                                                {t => (
                                                    <button
                                                        type="submit"
                                                        className="btn btn-info btn-block btn-lg mt-4"
                                                        disabled={waitRegCourse}
                                                    >
                                                        {waitRegCourse && (
                                                            <span
                                                                className="spinner-border"
                                                                role="status"
                                                                aria-hidden="true"
                                                            ></span>
                                                        )}
                                                        {t("Submit")}
                                                    </button>
                                                )}
                                            </Translation>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    waitRegCourse: state.waitRegCourse
});
const mapActionToProps = {
    RegOnlineCourse,
    defaultWait
};
export default withRouter(
    connect(mapStateToProps, mapActionToProps)(CourseReg)
);
