import { baseUrl } from "../../urls/urls";
import axios from "axios";

export const jobApllyData = data => dispatch => {
  let bodyFormData = new FormData();

  bodyFormData.append("name", data.name);
  bodyFormData.append("phone", data.phone);
  bodyFormData.append("mobile", data.phone);
  bodyFormData.append("branch_id", data.branch_id);
  bodyFormData.append("studies", data.studying);
  bodyFormData.append("experiences", data.experience);
  bodyFormData.append("address", data.adress);
  bodyFormData.append("cv", data.cv);
  bodyFormData.append("employment_job_id", data.job_id);
  bodyFormData.append("notes", data.note);

  axios({
    method: "post",
    url: baseUrl + "employment-request",
    data: bodyFormData,
    Headers:{
      "Accept" : "application/json"
    }
  })
    .then(res => {
      dispatch({
        type: "JOB_APPLY_SUCCESS",
        payload: res
      });
    })
    .catch(err => {
      dispatch({
        type: "JBO_APPLY_ERR",
        payload: err.response
      });
      if (err.response) {
      }
    });
};

export const chooseJob = lang => dispatch => {
  axios
    .get(baseUrl + `employment-job?local=${lang}`)
    .then(jobs => {
      dispatch({
        type: "CHOOSE_JOB_SUCCESS",
        payload: jobs.data
      });
    })
    .catch(err => {
      dispatch({
        type: "CHOOSE_JOB_ERR",
        payload: err
      });
      if (err.response) {
      }
    });
};
