import React, { Component } from "react";
import "./header.scss";
import {headerApi} from '../../../store/actions/header'
import {imageUrl} from '../../../urls/urls'
import placeholder from '../../../image/placeholder.jpg'
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import 'aos/dist/aos.css';
import { Translation } from 'react-i18next';
import Typed from 'react-typed';

const storageLang = localStorage.getItem('langLocalcStorage')
var finalLang = storageLang == null ? 'en' : storageLang

// var finalLang = storageLang == null ? navigator.language : storageLang

class Header extends Component {


  componentDidMount = () => {
    this.props.headerApi(finalLang)
  } 
  componentWillReceiveProps(nextProps)
  {
    this.typed.reset();
  }
 
  render() {
    const lang = localStorage.getItem("langLocalcStorage");
    const {headerData} = this.props

    const descHeader = headerData? (headerData['description']||'') : 'Welcome' 
    return (
      <header className="header pt-5">
        <div className="container mt-md-5">
          <div className="row">
            <div className="col-md-5 pt-md-5 mt-md-5 ">
              <h1 className='h2 text-md-left text-center text-white d-block mb-3' data-aos='fade-right' data-aos-duration="1800" > 
                {headerData? (headerData['title']||'') : null } 
              </h1>
                <Typed
                typedRef={(typed) => { this.typed = typed }}
                strings={[
                    `${descHeader}`
                    ]}
                    typeSpeed={40}
                    backSpeed={10}
                    className={'text-md-left text-center text-white d-inline-block mt-0 pt-0 text-section'}
                    loop
                     >
                </Typed>
              <div className="row">
                <div className="col-md-6 mb-5 mt-3">
                    <Translation>
                        {
                          t => 
                            <Link to='/student-registration' className="rounded-pill btn-info btn-block text-center d-block btn-lg mt-5 shadow hvr-forward" data-aos='fade-right' data-aos-duration="3000" block>
                            {t('title button')}
                            </Link>
                        }
                    </Translation>
                </div>
              </div>
            </div>
            <div className="col-md-6 offset-md-1" data-aos='fade-left' data-aos-duration="3000">
              {headerData? <img src={imageUrl+headerData['image']} style={lang == 'ar'?{transform: "scaleX(-1)"}:{}} className="img-fluid" alt="img"/> : <img src={placeholder} className="img-fluid" alt="img"/>  }
            </div>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProp = state => {
  return {
    headerData: state.headerData
  };
};

const mapDispatchToProps = dispatch => {
  return{
    headerApi : (lang) => dispatch(headerApi(lang)),
  }
}

export default connect(mapStateToProp, mapDispatchToProps)(Header);
