import React, { Component } from "react";
import { connect } from "react-redux";
import {coursesApi} from '../../store/actions/Courses' 
import "./coursers.scss";
import tie from "../../image/tie.svg";
import levels from "../../image/levels.svg";
import rocket from "../../image/rocket.svg";
import Polygon_back from "../../image/Polygon_back.svg";
import Polygon_front from "../../image/Polygon_front.svg";
import { Translation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';
import CoursesSummary from "./CoursesSummary";


const storageLang = localStorage.getItem('langLocalcStorage')
var finalLang = storageLang == null ? 'en' : storageLang

// var finalLang = storageLang == null ? navigator.language : storageLang


class CoursersPage extends Component {

  componentDidMount(){
    this.props.coursesApi(finalLang)
    AOS.init({
      duration : 2000
    })
  }
  


  render() {
    const { coursesData } = this.props;
    return (
      <div className=" Coursers py-5 ">
        <div className="container">
          <div className="header-photo-gallery ">
              <Translation>
                  {t => (
                      <h1 className="mb-0 pb-0 text-center" data-aos='zoom-in' data-aos-duration="3000" > 
                          {t("course_heading")}
                      </h1>
                  )}
              </Translation>
            <div className="row">
              <div className="col-md-10 mx-auto">
                <Translation>
                    {t => (
                      <p className="text-center" data-aos='zoom-in' data-aos-duration="3000">
                        {t("course_desc")}
                      </p>
                    )}
                </Translation>
                
              </div>
            </div>
          </div>
          
          <div className="row mt-5">
            {coursesData ? coursesData.map(item => {
              return (
                  <CoursesSummary 
                    id={item.id}
                    key={item.id}
                    data={item}
                  />
              )
            }) : null }
          </div>

          {/*<div className="row">*/}
          {/*  <div className="col-md-12 mt-4 border-right ">*/}
          {/*    <div className="card-group">*/}
          {/*      <div className="card pt-4 text-center" data-aos='zoom-in' data-aos-duration="2000">*/}
          {/*        <img src={rocket} className="img-fluid mx-auto" alt="rocket" />*/}
          {/*        <div className="card-body">*/}
          {/*        <Translation>*/}
          {/*            {t => (*/}
          {/*             <h4 className="card-title mt-3"> {t("courses_card_title_one")}</h4>*/}
          {/*            )}*/}
          {/*        </Translation>*/}
          {/*        <Translation>*/}
          {/*            {t => (*/}
          {/*               <p className="card-text text-muted">  {t("courses_card_desc_one")}  </p>*/}
          {/*            )}*/}
          {/*        </Translation>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div className="card pt-4 text-center" data-aos='zoom-in' data-aos-duration="4000">*/}
          {/*        <img src={levels} className="img-fluid mx-auto" alt="levels" />*/}
          {/*        <div className="card-body">*/}
          {/*        <Translation>*/}
          {/*            {t => (*/}
          {/*             <h4 className="card-title mt-3"> {t("courses_card_title_tow")}</h4>*/}
          {/*            )}*/}
          {/*        </Translation>*/}
          {/*        <Translation>*/}
          {/*            {t => (*/}
          {/*               <p className="card-text text-muted">  {t("courses_card_desc_tow")}  </p>*/}
          {/*            )}*/}
          {/*        </Translation>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div className="card pt-4 text-center" data-aos='zoom-in' data-aos-duration="6000">*/}
          {/*        <div className="card-body">*/}
          {/*        <img src={tie} className="img-fluid mx-auto" alt="tie" />*/}
          {/*        <Translation>*/}
          {/*            {t => (*/}
          {/*             <h4 className="card-title mt-3"> {t("courses_card_title_three")}</h4>*/}
          {/*            )}*/}
          {/*        </Translation>*/}
          {/*        <Translation>*/}
          {/*            {t => (*/}
          {/*               <p className="card-text text-muted">  {t("courses_card_desc_three")}  </p>*/}
          {/*            )}*/}
          {/*        </Translation>*/}
          {/*         */}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
        <div className="Polygon_back" data-aos='fade-left' data-aos-duration="6000" data-aos-delay="3500">
          <img src={Polygon_back} className="img-fluid" alt="Polygon_back" />
        </div>
        <div className="Polygon_front" data-aos='fade-right' data-aos-duration="6000" data-aos-delay="6300">
          <img src={Polygon_front} className="img-fluid" alt="Polygon_front" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    coursesData: state.coursesData
  };
};


const mapDispatchToProps = dispatch => {
  return{
    coursesApi : (lang) => dispatch(coursesApi(lang)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps )(CoursersPage);
