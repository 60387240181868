import React, { Component } from "react";
import visa_card from "../../image/visa_card.jpg";
import master_card from "../../image/master_card.jpg";
import { connect } from "react-redux";
import CourseReg from "./CourseReg";
import { Navgator, SignForCourse, Chat } from "./navgator";
import complate from "../../image/task-complete.png";
import { withRouter } from "react-router-dom";
import error from "../../image/error.png";
import queryString from "query-string";
import Cookies from 'universal-cookie';
import ReactGA from "react-ga";

class PaymentMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentMethod: null
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  paymentBy = type => {
    if (this.props.onlineCourse) {
      this.setState({ paymentMethod: type });
    }
  };

  render() {
    const cookies = new Cookies();
    const fb_ad_id = cookies.get('foreign_student_fb_ad_id');
    const { currency, onlineCourse } = this.props;
    const { paymentMethod } = this.state;
    const params = queryString.parse(this.props.location.search);
    if (params.price){
      window.fbq('track', 'Purchase', {value: params.price, currency: 'USD'});
      ReactGA.event({
        category: 'Student',
        action: 'Purchased a Course'
      });
    }
    this.props.location.search = "";
    return (
      <React.Fragment>
        {onlineCourse && (
          <div className="row">
            <div className="col-md-8 mx-auto">
              <div className="header-desc-course bg-white p-4 border rounded mb-4  shadow">
                <h1 className="h3 mb-0">
                  {onlineCourse.name}
                  <span className="float-right text-info">
                    {" "}
                    {currency == "USD"
                      ? onlineCourse.usd_price - onlineCourse.usd_discount
                      : onlineCourse.egp_price - onlineCourse.egp_discount}
                    {currency == "USD" ? " دولار" : " جنيه"}
                  </span>
                </h1>
              </div>
            </div>
          </div>
        )}
        {paymentMethod ? (
          <CourseReg
              fb_ad_id = {fb_ad_id}
            onlineCourseId={this.props.onlineCourse.id}
            currency={this.props.currency}
            paymentMethod={paymentMethod}
          />
        ) : params.status ? (
          <div className="col-md-8 mx-auto border p-5 rounded custme-popup bg-white">
            <img
              src={params.status == "success" ? complate : error}
              className="img-fluid mx-auto d-block mt-n5"
              alt=""
            />
            <br />
            <h2 className={params.status == "success" ? "text-success text-center mt-n5" : "text-danger text-center mt-n5"}>{params.title}</h2>
            <br />
            <h4 className={params.status == "success" ? "text-success text-center" : "text-danger text-center"}> {params.message} </h4>
            {params.status != "success" ? (
                <div>
            <br/><br/>
            <h4 className="text-info text-center">اذا واجهتك اي مشكلة خلال الحجز يرجي تجربة حجز كورس اخر او التواصل معنا</h4>
            <SignForCourse setNav={this.props.setItem} />
                </div>) : null}
          </div>
        ) : (
          <div className="PaymentMethod mt-5 pb-5">
            <h1 className="text-center h3">
              طرق الدفع المتاحه لسداد قيمة الدبلومه
            </h1>

            <div className="col-md-8 mx-auto mt-5">
              <div className="row">
                <div className="col-6">
                  <div
                    className="item d-flex align-items-center"
                    onClick={e => {
                      this.paymentBy("Card");
                    }}
                  >
                    <img
                      src={visa_card}
                      onClick={e => {
                        this.paymentBy("Card");
                      }}
                      className="img-fluid d-block mx-auto cursor-pointer"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-6 ">
                  <div
                    className="item d-flex align-items-center "
                    onClick={e => {
                      this.paymentBy("Card");
                    }}
                  >
                    <img
                      onClick={e => {
                        this.paymentBy("Card");
                      }}
                      src={master_card}
                      className="img-fluid d-block mx-auto cursor-pointer"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

          </div>
        )}
        <div className="PaymentMethod PaymentMethodEG mt-5 pb-5">
        <div className="note mt-5">
          <div className="row">
            <div className="col-md-12">
              <div className="content-note px-5">
                <h4 className="text-white text-center">
                  ملحوظة عند تسجيل بيانتك إنتبه جيداً عند كتابة بريدك
                  الألكتروني لإننا سوف نقوم بإرسال رسالة تأكيد الحجز عليه
                </h4>
              </div>
            </div>
          </div>
        </div>

          <div className="note-payment-eg mt-5 w-100">
            <div className="row">
              <div className="col-md-12">
                <div className="content-note">
                  <h5 className="text-black text-center p-3">
                    طرق استلام الكتب لغير المصريين من أشقائنا العرب:
                    سيتم ارسالها من خلال مكاتب الإرسال السريع DHL على العنوان المُسجل لدينا أو إستلامها ككتب إلكترونية على حسب الرغبة
                  </h5>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <Navgator prev="Cost" next="Question" setNav={this.props.setItem} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  onlineCourse: state.onlineCourse
});
export default withRouter(connect(mapStateToProps)(PaymentMethod));
