import React, { Component } from "react";
import "./branchesPage.scss";
import { brancheApi } from "../../store/actions/branches";
import { connect } from "react-redux";
import coummuntiy_right from "../../image/branch_rigth.svg";
import placeholder from "../../image/placeholder.png";
import title_branch from "../../image/title.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Translation } from "react-i18next";
import { imageUrl } from "../../urls/urls";

const storageLang = localStorage.getItem("langLocalcStorage");
var finalLang = storageLang == null ? "ar" : "ar";

// var finalLang = storageLang === null ? navigator.language : storageLang

class BranchesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brancheDataState: props.brancheData,
      active: 2
    };
  }
  componentDidMount() {
    AOS.init({
      duration: 2000
    });
    const { location, brancheApi, brancheData } = this.props;
    brancheApi(finalLang);
    if (brancheData.length > 0) {
      this.setState({
        active: location.state
          ? brancheData.find(branch => branch.id == location.state.branchId)
          : brancheData[0],
        brancheData: brancheData.map(p => p.id)
      });
    }
  }
  componentWillReceiveProps(newProps) {
    const { location, brancheData } = newProps;
    if (brancheData.length > 0) {
      this.setState({
        active: location.state
          ? brancheData.find(branch => branch.id == location.state.branchId)
          : brancheData[0],
        brancheData: brancheData.map(p => p.id)
      });
    }
  }

  render() {
    const { brancheData } = this.props;
    return (
      <div className="BranchesPage py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="parent-nav border rounded bg-white py-4 mb-3">
                <div className="header-photo-gallery border-bottom px-3">
                  <Translation>
                    {t => (
                      <h1
                        className=""
                        data-aos="zoom-in"
                        data-aos-duration="3000"
                      >
                        {t("Branches_Name")}
                      </h1>
                    )}
                  </Translation>
                  <Translation>
                    {t => (
                      <p
                        className=""
                        data-aos="zoom-in"
                        data-aos-duration="3000"
                      >
                        {t("Branches_desc")}
                      </p>
                    )}
                  </Translation>
                </div>
                <div
                  className="nav flex-column nav-pills "
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <div className="col-12">
                    <div className="row">
                      {brancheData
                        ? brancheData
                            .map(b => b)
                            .filter(
                              (b, index, self) => self.indexOf(b) === index
                            )
                            .map(b => (
                              <a
                                key={b.id}
                                className={`nav-link col-4 col-md-12 ${
                                  this.state.active.id === b.id
                                    ? "active text-white border-info"
                                    : null
                                }`}
                                data-toggle="pill"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected={
                                  this.state.active.id === b.id
                                    ? "true"
                                    : "false"
                                }
                                onClick={() => this.setState({ active: b })}
                              >
                                {b ? b.name : null}
                              </a>
                            ))
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-home"
                  role="tabpanel"
                  aria-labelledby="v-pills-home-tab"
                >
                  <h2 className="mb-4">
                    {" "}
                    <img
                      src={title_branch}
                      className="img-fluid mr-2"
                      alt="real soft"
                    />
                    {this.state.active ? this.state.active.name : null}
                  </h2>

                  <div className="row">
                    <div className="col-md-12">
                      <img
                        src={
                          this.state.active.photo
                            ? imageUrl + this.state.active.photo
                            : placeholder
                        }
                        className="img-fluid w-100 rounded image-branch mb-4"
                        alt="real soft"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="mapouter col-md-12 mt-3" id="location">
                      <address>
                        {this.state.active ? this.state.active.address : null}
                      </address>
                      <div className="gmap_canvas border rounded">
                        <iframe
                          title="url for map"
                          className="w-100 maps"
                          id="gmap_canvas"
                          src={
                            this.state.active.lat
                              ? "https://maps.google.com/maps?q=" +
                                this.state.active.lat +
                                "," +
                                this.state.active.long +
                                "&z=15&output=embed"
                              : null
                          }
                          frameBorder="0"
                          scrolling="no"
                          marginHeight="0"
                          marginWidth="0"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="clip-path-right"
            data-aos="fade-left"
            data-aos-duration="6000"
            data-aos-delay="3500"
          >
            <img
              src={coummuntiy_right}
              className="img-fluid"
              alt="coummuntiy_right"
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    brancheData: state.brancheData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    brancheApi: lang => dispatch(brancheApi(lang))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BranchesPage);
