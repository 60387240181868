import React, {Component} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ScrollToTop from "react-router-scroll-top";
import "./gstyle.scss";
import {BrowserRouter, Switch, Route} from "react-router-dom";
import HomePage from "./components/homePage/HomePage";
import NavbarWowmix from "./components/layout/navbar/NavbarWowmix";
import Footer from "./components/layout/footer/Footer";
import CoursersPage from "./components/coursers/CoursersPage";
import AboutUs from "./components/aboutUs/AboutUs";
import ContactUs from "./components/contactUs/ContactUs";
import BranchesPage from "./components/branchesPage/BranchesPage";
import GalleryPage from "./components/galleryPage/GalleryPage";
import StudentReg from "./components/contactUs/StudentReg";
import JobAplly from "./components/Jobs/JobAplly";
import CourseDesc from "./components/coursers/CourseDesc";
import {chooseJob} from "./store/actions/job";
import {brancheApi} from "./store/actions/branches";
import {connect} from "react-redux";
import RegCourses from "./components/regCourses/RegCourses";
import OnlineReg from "./components/OnlineReg/OnlineReg";
import StudySystem from "./components/OnlineReg/StudySystem";
import Cost from "./components/OnlineReg/Cost";
import PaymentMethod from "./components/OnlineReg/PaymentMethod";
import Question from "./components/OnlineReg/Question";
import PrevStudents from "./components/OnlineReg/PrevStudents";
import PaymentMethodEG from "./components/OnlineReg/PaymentMethodEG";
import ChildReg from "./components/contactUs/ChildReg";

const storageLang = localStorage.getItem("langLocalcStorage");
var finalLang = storageLang === null ? 'ar' : storageLang;

class App extends Component {
    componentDidMount() {
        this.props.chooseJob(finalLang);
        this.props.brancheApi();
    }

    render() {
        const DefaultContainer = () => (
            <div>
                <NavbarWowmix/>
                <Route exact path="/" component={HomePage}/>
                <Route path="/about-us" component={AboutUs}/>
                <Route path="/gallery" component={GalleryPage}/>
                <Route path="/contact-us" component={ContactUs}/>
                <Route path="/branches" component={BranchesPage}/>
                <Route path="/course-registration" component={RegCourses}/>
                <Route path="/jobs" component={JobAplly}/>
                {/* <Route path="/student-registration/:lead_id" component={StudentReg}/> */}
                <Route path="/student-registration" component={StudentReg}/>
                <Route path="/child-registration" component={ChildReg}/>
                <Route path="/courses" component={CoursersPage}/>
                <Route
                    path="/course-details/:CourseDetail_id"
                    component={CourseDesc}
                />
                <Footer/>
            </div>
        )

        return (
            <BrowserRouter>
                <ScrollToTop>
                    <div className="App">
                        <Switch>
                            <Route exact path="/online-registration" component={OnlineReg}/>
                            <Route
                                exact
                                path="/online-registrationEg"
                                render={(routeProps) => {
                                    return (<OnlineReg {...routeProps} currency={"EGP"}/>)
                                }}
                            />
                            <Route component={DefaultContainer}/>
                        </Switch>
                    </div>
                </ScrollToTop>
            </BrowserRouter>
        );
    }
}

export default connect(null, {brancheApi, chooseJob})(App);
