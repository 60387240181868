import React, { Component } from "react";
import { chooseJob, jobApllyData } from "../../store/actions/job";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { Translation } from "react-i18next";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: toast => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  }
});

const storageLang = localStorage.getItem("langLocalcStorage");
var finalLang = storageLang === null ? "en" : storageLang;

class JobAplly extends Component {
  state = {
    name: "",
    phone: "",
    branch_id: "",
    studying: "",
    experience: "",
    adress: "",
    cv: "",
    job_id: "",
    note: ""
  };

  componentDidMount = () => {
    this.props.chooseJob(finalLang);
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.job_aplly) {
      switch (nextProps.job_aplly.status) {
        case 200:
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500
          });
          this.setState({
            name: "",
            phone: "",
            branch_id: "",
            studying: "",
            experience: "",
            adress: "",
            cv: "",
            job_id: "",
            note: ""
          });
          this.props.Done();
          break;
        case 422:
          let error = [];
            Object.entries(nextProps.job_aplly.data.errors).forEach(([key, value]) => {
              error.push(value[0])
              
            });
            Toast.fire({
              icon: "error",
              title: error.join('\n')
            });
          break;
        default:
      }
    }
  }

  CV_file_getBase64 = () => {
    const imageNew = document.getElementById("cv").files[0];
    const reader = new FileReader();
    reader.readAsDataURL(imageNew);
    reader.onload = () => {
      this.setState({ cv: reader.result });
    };
    reader.onerror = error => {};
  };

  onDrop = async e => {
    await this.setState({
      cv: e.target.files[0]
    });
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handlePhoneChange = e => {
    e.target.value = e.target.value
      .replace(/[٠١٢٣٤٥٦٧٨٩]/g, function(d) {
        return d.charCodeAt(0) - 1632;
      })
      .replace(/[۰۱۲۳۴۵۶۷۸۹]/g, function(d) {
        return d.charCodeAt(0) - 1776;
      });
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      name,
      phone,
      studying,
      experience,
      adress,
      job_id,
      branch_id,
      cv
    } = this.state;
    const regex = /01[0-2|5]{1}[0-9]{8}/;
    if (!name) {
      Toast.fire({
        icon: "error",
        title: "Please Enter Name"
      });
    } else if (!regex.test(phone)) {
      Toast.fire({
        icon: "error",
        title: "Please Enter Valid Phone Number"
      });
    } else if (!studying) {
      Toast.fire({
        icon: "error",
        title: "Please Enter studies"
      });
    } else if (!job_id) {
      Toast.fire({
        icon: "error",
        title: "Please Enter Job"
      });
    } else if (!branch_id) {
      Toast.fire({
        icon: "error",
        title: "Please Enter branch"
      });
    } else if (!experience) {
      Toast.fire({
        icon: "error",
        title: "Please choose experience"
      });
    } else if (!adress) {
      Toast.fire({
        icon: "error",
        title: "Please Enter address"
      });
    } else {
      this.props.jobApllyData(this.state);
    }
  };

  render() {
    const { jobs, branches, job_aplly } = this.props;
    return (
      <div className="TeacherReg py-5">
        <div className="container">
          <Translation>
            {t => <h1 className="text-center">{t("job_title")}</h1>}
          </Translation>
          <div className="col-md-10 mx-auto">
            <form className="mt-5" onSubmit={this.handleSubmit}>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group mb-4">
                    <Translation>
                      {t => (
                        <input
                          value={this.state.name}
                          onChange={this.handleChange}
                          type="text"
                          id="name"
                          className="form-control form-control-lg"
                          placeholder={t("placeholder_name")}
                        />
                      )}
                    </Translation>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-4">
                    <Translation>
                      {t => (
                        <input
                          value={this.state.phone}
                          onChange={this.handlePhoneChange}
                          type="tel"
                          pattern="^01[0-2|5]{1}[0-9]{8}"
                          maxLength="11"
                          id="phone"
                          className="form-control form-control-lg"
                          placeholder={t("placeholder_phone")}
                        />
                      )}
                    </Translation>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-4">
                    <Translation>
                      {t => (
                        <input
                          value={this.state.studying}
                          onChange={this.handleChange}
                          type="text"
                          id="studying"
                          className="form-control form-control-lg"
                          placeholder={t("placeholder_studying")}
                        />
                      )}
                    </Translation>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-4">
                    <select
                      name=""
                      id="job_id"
                      value={this.state.job_id}
                      className="form-control form-control-lg"
                      onChange={this.handleChange}
                    >
                      <Translation>
                        {t => (
                          <option value="">
                            {" "}
                            {t("placeholder_choose_jobs")}{" "}
                          </option>
                        )}
                      </Translation>
                      {jobs
                        ? jobs.map((job, i) => (
                            <option key={i} value={job.id}>
                              {" "}
                              {job.name}{" "}
                            </option>
                          ))
                        : null}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-4">
                    <select
                      name=""
                      id="branch_id"
                      value={this.state.branch_id}
                      className="form-control form-control-lg"
                      onChange={this.handleChange}
                    >
                      <Translation>
                        {t => (
                          <option value=""> {t("placeholder_branche")} </option>
                        )}
                      </Translation>
                      {branches
                        ? branches.map(branche => {
                            return (
                              <option key={branche.id} value={branche.id}>
                                {" "}
                                {branche.name}{" "}
                              </option>
                            );
                          })
                        : null}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group mb-4">
                    <select
                      name=""
                      id="experience"
                      value={this.state.experience}
                      className="form-control form-control-lg"
                      onChange={this.handleChange}
                    >
                      <Translation>
                        {t => (
                          <option value="">
                            {" "}
                            {t("placeholder_Experience")}{" "}
                          </option>
                        )}
                      </Translation>
                      <option value="Fresh"> Fresh </option>
                      <option value="+1 Year"> +1 Year </option>
                      <option value="+2 Year"> +2 Year </option>
                      <option value="+3 Year"> +3 Year </option>
                      <option value="+5 Year"> +5 Year </option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-4">
                    <Translation>
                      {t => (
                        <input
                          value={this.state.adress}
                          onChange={this.handleChange}
                          type="text"
                          id="adress"
                          className="form-control form-control-lg"
                          placeholder={t("placeholder_Adress")}
                        />
                      )}
                    </Translation>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-4">
                    <input
                      onChange={this.onDrop}
                      type="file"
                      id="cv"
                      className="form-control form-control-lg"
                      placeholder="cv"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <Translation>
                    {t => (
                      <textarea
                        onChange={this.handleChange}
                        value={this.state.note}
                        className="form-control form-control-lg"
                        id="note"
                        placeholder={t("paceholder_Note")}
                      ></textarea>
                    )}
                  </Translation>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 ml-auto col-6">
                  <Translation>
                    {t => (
                      <button
                        type="submit"
                        className="btn btn-info btn-lg btn-block mt-4"
                      >
                        {t("send_button")}
                      </button>
                    )}
                  </Translation>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    jobs: state.jobs,
    branches: state.brancheData,
    job_aplly: state.job_aplly
  };
};

const mapDispatchToProps = dispatch => {
  return {
    jobApllyData: data => dispatch(jobApllyData(data)),
    chooseJob: lang => dispatch(chooseJob(lang)),
    Done: () => {
      dispatch({
        type: "JOB_APPLY_SUCCESS",
        payload: null
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobAplly);
