import React, { Component } from "react";
import { stepThree, reset } from "../../store/actions/studentRegApi";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import success from '../../image/seccess.svg'
import Swal from "sweetalert2";
import validator from 'validator';

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: toast => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  }
});

class RegCoursesStepTow extends Component {
  state = {
    email: "",
    university: "",
    faculty: "",
    address: "",
    national_id: "",
    lead_id: this.props.studentStepOne.data.lead_id,
    sec: false
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { email, university, faculty, address, national_id } = this.state;
    if ( !validator.isEmail(email)) {
      Toast.fire({
        icon: "error",
        title: "Please Enter valid email"
      });
    } else if (!national_id && national_id.length != 14) {
      Toast.fire({
        icon: "error",
        title: "Please Enter National ID"
      });
    } else {
      this.props.stepThree(this.state);
      if (this.props.studentStepThree.status === 200) {
        this.setState({
          email: "",
          university: "",
          faculty: "",
          address: "",
          national_id: "",
          lead_id: this.props.lead_id
        });
        this.props.Done();
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.studentStepThree.status === 200) {
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: ' Your details have been successfully registered - thanks for your trust ',
        showConfirmButton: false,
        timer: 2500
      })
      this.setState({ sec: !this.state.sec });
    }
  }

  componentWillUnmount(){
    this.props.reset()
  }


  render() {
    return (
      <div>
        {!this.state.sec ? (
          <div className="description border rounded shadow p-4 mb-3" >  
           <div className="row">
          <div className="col-md-4">
            <h4 className="mt-2" >Register Course</h4>
          </div>
          <div className="col-md-5 ml-auto">
            <span className="item-steps bg-info  d-inline-block">
              <h6 className="text-center " > 2 </h6>
            </span>
            <span className="item-steps bg-info d-inline-block">
              <h6 className="text-center " > 1 </h6>
            </span>
          </div>
        </div>
          <form
             onSubmit={this.handleSubmit}
             noValidate
             autoComplete="off"
             className="my-3 mt-4"
          >
            <div className="row">
              <div className="col-md-6">
                <div className="form-group mb-4">
                  <Translation>
                    {t => (
                      <input
                        type="email"
                        onChange={this.handleChange}
                        placeholder={t("Student_Email")}
                        id="email"
                        className="form-control form-control-lg"
                        value={this.state.email}
                      />
                    )}
                  </Translation>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-4">
                  <Translation>
                    {t => (
                      <input
                        type="text"
                        onChange={this.handleChange}
                        placeholder={t("student_University")}
                        id="university"
                        className="form-control form-control-lg"
                        value={this.state.university}
                      />
                    )}
                  </Translation>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-4">
                  <Translation>
                    {t => (
                      <input
                        type="text"
                        onChange={this.handleChange}
                        placeholder={t("Student_Select_College")}
                        id="faculty"
                        className="form-control form-control-lg"
                        value={this.state.faculty}
                      />
                    )}
                  </Translation>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-4">
                  <Translation>
                    {t => (
                      <input
                        type="text"
                        maxLength="14"
                        onChange={this.handleChange}
                        placeholder={t("student_National_id")}
                        id="national_id"
                        className="form-control form-control-lg"
                        value={this.state.national_id}
                      />
                    )}
                  </Translation>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mb-4">
                  <Translation>
                    {t => (
                      <input
                        type="text"
                        onChange={this.handleChange}
                        placeholder={t("student_Adress")}
                        id="address"
                        className="form-control form-control-lg"
                        value={this.state.address}
                      />
                    )}
                  </Translation>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 ml-auto">
                <Translation>
                  {t => (
                    <button className="btn btn-info btn-block btn-lg mt-4">
                      {" "}
                      {t("button_Next")}{" "}
                    </button>
                  )}
                </Translation>
              </div>
            </div>
          </form>
          </div>
        ) : null }
      </div>
    );
  }
}


const mapStateToProps = state => {
    return {
      studentStepOne: state.studentStepOne,
      studentStepThree: state.studentStepThree
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      stepThree: data => dispatch(stepThree(data)),
      reset: () => dispatch(reset()),
    };
  };

export default connect(mapStateToProps, mapDispatchToProps )(RegCoursesStepTow)