import React, { Component } from "react";
import "./gallery.scss";
import { connect } from "react-redux";
import { galleryApi } from "../../../store/actions/gallery";
import { imageUrl } from "../../../urls/urls";
import placeholder from "../../../image/placeholder.png";
import "aos/dist/aos.css";
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";

const storageLang = localStorage.getItem('langLocalcStorage')
var finalLang = storageLang == null ? 'en' : storageLang

// var finalLang = storageLang == null ? navigator.language : storageLang


class Gallery extends Component {
  componentDidMount = () => {
    this.props.galleryApi(finalLang);
  };

  render() {
    const { gallery_info } = this.props;

    return (
      <div className="gallery py-5 ">
        <div className="container ">
          <div
            className="header-photo-gallery "
            data-aos="zoom-in"
            data-aos-duration="3000"
          >
            <Translation>
              {t => <h1 className="text-center">{t("Photo_Gallery")}</h1>}
            </Translation>
            <div className="row">
              <div className="col-md-10 mx-auto">
                <Translation>
                  {t => <p className="text-center">{t("Gallery_desc")}</p>}
                </Translation>
              </div>
            </div>
          </div>
          <div className="photos mt-5">
            <div className="row">
              {gallery_info
                ? gallery_info.slice(0,8).map((item, index) => {
                    return (
                      <div
                        id={item.id}
                        key={item.id}
                        className={
                          index === 0
                            ? "col-md-4 col-6"
                            : index === 1
                            ? "col-md-5 col-6"
                            : index === 2
                            ? "col-md-3 col-6"
                            : index === 3
                            ? "col-md-3 col-6"
                            : index === 4
                            ? "col-md-4 col-6"
                            : index === 5 
                            ? "col-md-5 col-6"
                            : index === 6
                            ? "col-md-5 col-6"
                            : index === 7 && "col-md-4 col-6"
                        }
                      >
                        <img
                          src={item ? imageUrl + item.image : placeholder}
                          className="img-fluid w-100 mb-4 test-one"
                          alt="realsoft"
                          data-aos="fade-down-right"
                          data-aos-duration="5000"
                        />
                      </div>
                    );
                  })
                : null}
                <div className="col-md-3">
                <Link to="/gallery">
                <div
                  className="Coursers-item-path hvr-bob w-100 mb-4"
                  data-aos-duration="3000"
                >
                  <div className="desc-res-item">
                    <Translation>
                      {t => <h4 className="text-center text-white pt-5 mt-4" > {t("see_more")} </h4>}
                    </Translation>
                  </div>
                </div>
              </Link>
                  {/* <Link className="bg-info"  to='/'>
                    المزيد من الصور
                  </Link> */}
                </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    gallery_info: state.gallery
  };
};

const mapDispatchToProps = dispatch => {
  return {
    galleryApi: (lang) => dispatch(galleryApi(lang))
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
