const initState = {
  headerData: [],
  coursesCategories: [],
  onlineCourses: [],
  coursesData: [],
  get_countData: [],
  gallery: [],
  brancheData: [],
  video: [],
  jobs: [],
  job_aplly: [],
  course_id: [],
  child_reg: [],
  studentStepOne: [],
  studentStepTow: [],
  studentStepThree: [],
  pixels: [],
  category_by_id: [],
  sliderInfo: [],
  contact_Us_res: [],
  onlineSliderData: [],
  SliderPrevStudentsData: [],
  franchise_res: null,
  onlineCourse: null,
  waitRegCourse: false,
  signCourse: false
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case "SIGN_COURSE":
      return {
        ...state,
        signCourse: true
      };
    case "WAIT_REG_COURSE":
      return {
        ...state,
        waitRegCourse: true
      };
    case "DEFAULT_WAIT_REG_COURSE":
      return {
        ...state,
        waitRegCourse: false
      };
    case "HEADER_SUCSSESS":
      return {
        ...state,
        headerData: action.payload
      };

    case "REGISTER_ONLINE_COURSE":
      return {
        ...state,
        onlineCourse: action.payload,
        signCourse: false
      };

    case "DEFAULT_ONLINE_COURSE":
      return {
        ...state,
        onlineCourse: null
      };

    case "COURSES_SUCCESS":
      return {
        ...state,
        coursesData: action.payload
      };
    case "ONLINE_COURSES_SUCCESS":
      return {
        ...state,
        onlineCourses: action.payload
      };

    case "Get_COUNT_SUCSSESS":
      return {
        ...state,
        get_countData: action.payload
      };

    case "GALLER_DATA_SUCSSESS":
      return {
        ...state,
        gallery: action.payload
      };

    case "BRANCHE_SUCCESS":
      return {
        ...state,
        brancheData: action.payload
      };

    case "VIDEO_SUCSSESS":
      return {
        ...state,
        video: action.payload
      };

    case "JOB_APPLY_SUCCESS":
      return {
        ...state,
        job_aplly: action.payload
      };

    case "JBO_APPLY_ERR":
      return {
        ...state,
        job_aplly: action.payload
      };

    case "CHOOSE_JOB_SUCCESS":
      return {
        ...state,
        jobs: action.payload
      };

    case "COURSE_BY_ID_SUCCESS":
      return {
        ...state,
        course_id: action.payload
      };

    case "CHILD_REG_SUCCESS":
      return {
        ...state,
        child_reg: action.payload
      };

    case "STEP_ONE_SUCSSESS":
      return {
        ...state,
        studentStepOne: action.payload
      };

    case "STEP_TOW_SUCSSESS":
      return {
        ...state,
        studentStepTow: action.payload
      };

    case "STEP_THREE_SUCSSESS":
      return {
        ...state,
        studentStepThree: action.payload
      };

    case "CONTACT_US_SECCESS":
      return {
        ...state,
        contact_Us_res: action.payload
      };

    case "COURSES_CATEGORIES_SUCCESS":
      return {
        ...state,
        coursesCategories: action.payload
      };

    case "PIXELS_SUCCESS":
      return {
        ...state,
        pixels: action.payload
      };

    case "SLIDER_ONLINE_REG_SUCSSESS":
      return {
        ...state,
        onlineSliderData: action.payload
      };

    case "SLIDER_PERV_STUDENT_SUCSSESS":
      return {
        ...state,
        SliderPrevStudentsData: action.payload
      };

    default:
      return state;
  }
};

export default reducer;
