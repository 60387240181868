import React, { Component } from "react";
import { connect } from "react-redux";
import {
  registerOnlineCourse,
  onlineCoursesApi,
} from "../../store/actions/Courses";
import { Navgator, SignForCourse, Chat } from "./navgator";

const viewData = ["name", "level", "usd_price", "duration_text"];
const viewColor = ["info", "warning", "light", "danger"];
const data = ["الاسم", "المستويات", "التكلفة", "المدة"];

class Cost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onlineCourses: props.onlineCourses,
    };
    props.onlineCoursesApi();
    this.courseRef = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.signCourse &&
      window.scrollTo(0, this.courseRef.current.offsetTop);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ onlineCourses: nextProps.onlineCourses });
    nextProps.signCourse &&
      window.scrollTo(0, this.courseRef.current.offsetTop);
  }

  render() {
    const { onlineCourses } = this.state;
    return (
      <div id="Cost">
        <h1 ref={this.courseRef} className="text-center h3 mb-3 pt-5 ">
          {" "}
          اختر الباقة المناسبه وسجل الان{" "}
        </h1>

        <div className="card-group text-center mt-5">
          {onlineCourses.map((course, i) => {
            return (
              <div key={i} className="card text-center pt-4">
                <div className="card-body text-center">
                  <h5 className="card-title text-center font-weight-bold">
                    {course.name}
                  </h5>
                  <h2 className="text-center my-4 font-main">
                    {course.usd_price - course.usd_discount}
                    <small>دولار</small>
                  </h2>
                  <h4 className="text-center my-4">بدلا من</h4>
                  <h2 className="text-center my-4 font-discount">
                    <s>{course.usd_price}</s>
                    <small>دولار</small>
                  </h2>
                  <button
                    onClick={(e) => {
                      window.fbq("track", "InitiateCheckout");
                      this.props.setItem("PaymentMethod");
                      this.props.registerOnlineCourse(course);
                    }}
                    className={`btn btn-${viewColor[i]} rounded-pill px-5 mt-3`}
                  >
                    {" "}
                    سجل الان{" "}
                  </button>
                </div>
              </div>
            );
          })}
        </div>

        <h1 className="text-center h3 my-5"> التفاصيل لكل دبلومة</h1>

        <div className="row">
          {/*<div className="col-12 d-block d-sm-none">*/}
          {/*  <div className="item-table mb-3  rounded p-3">*/}
          {/*    <ul className="list-unstyled p-0">*/}
          {/*      <li className="font-weight-bold text-center ">*/}
          {/*        {" "}*/}
          {/*        En Course Introduction{" "}*/}
          {/*      </li>*/}
          {/*      <li>*/}
          {/*        {" "}*/}
          {/*        المستويات{" "}*/}
          {/*        <small className="float-right ml-auto color-info">*/}
          {/*          {" "}*/}
          {/*          3{" "}*/}
          {/*        </small>{" "}*/}
          {/*      </li>*/}
          {/*      <li>*/}
          {/*        {" "}*/}
          {/*        المدة{" "}*/}
          {/*        <small className="float-right ml-auto color-info">*/}
          {/*          {" "}*/}
          {/*          شهر ونص*/}
          {/*        </small>{" "}*/}
          {/*      </li>*/}
          {/*      <li>*/}
          {/*        {" "}*/}
          {/*        التكلفة{" "}*/}
          {/*        <small className=" ml-auto float-right color-info">*/}
          {/*          {" "}*/}
          {/*          50${" "}*/}
          {/*        </small>*/}
          {/*      </li>*/}
          {/*    </ul>*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className="col-md-12">
            <table className="table table-responsive-md">
              <tbody>
                {data.map((da, i) => {
                  return (
                    <tr key={i}>
                      <td>{da}</td>
                      {onlineCourses.map((cours, n) => {
                        return (
                          <th className={`color-${viewColor[n]}`} key={n}>
                            {cours[viewData[i]]}
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        <div className="row mt-4">
          <Navgator
            prev="StudySystem"
            next="PaymentMethod"
            setNav={this.props.setItem}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  onlineCourses: state.onlineCourses,
  signCourse: state.signCourse,
});
export default connect(mapStateToProps, {
  registerOnlineCourse: registerOnlineCourse,
  onlineCoursesApi,
})(Cost);
