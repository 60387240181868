import React, { Component } from "react";
import { stepThree } from "../../store/actions/studentRegApi";
import { connect } from "react-redux";
import { Translation } from "react-i18next";
import success from "../../image/seccess.svg";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import validator from "validator";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: toast => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  }
});

class StudentStepThree extends Component {
  state = {
    lead_id: this.props.studentStepOne.data.lead_id,
    email: "",
    university: "",
    faculty: "",
    address: "",
    national_id: "",
    sec: false
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { email, university, faculty, address, national_id } = this.state;
    if ( !validator.isEmail(email)) {
      Toast.fire({
        icon: "error",
        title: "Please Enter valid email"
      });
    } else if (!national_id && national_id.length != 14) {
      Toast.fire({
        icon: "error",
        title: "Please Enter Valid National ID"
      });
    } else {
      this.props.stepThree(this.state);
      if (this.props.studentStepThree.status === 200) {
        this.props.resetState();
        this.props.resetState2();
        this.setState({
          email: "",
          university: "",
          faculty: "",
          address: "",
          national_id: ""
        });
        this.props.Done();
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.studentStepThree.status === 200) {
      this.setState({ sec: !this.state.sec });
    }
  }

  render() {
    return (
      <div>
        {!this.state.sec ? (
          <form
            noValidate
            autoComplete="off"
            className="my-3"
            onSubmit={this.handleSubmit}
          >
            <div className="row mb-4">
              <div className="col-md-5 mx-auto">
                <span className="item-steps bg-info d-inline-block">
                  <h6 className="text-center "> 3 </h6>
                </span>
                <span className="item-steps bg-info d-inline-block">
                  <h6 className="text-center "> 2 </h6>
                </span>
                <span className="item-steps bg-info d-inline-block">
                  <h6 className="text-center "> 1 </h6>
                </span>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group mb-4">
                  <Translation>
                    {t => (
                      <input
                        type="email"
                        onChange={this.handleChange}
                        placeholder={t("Student_Email")}
                        id="email"
                        className="form-control form-control-lg"
                        value={this.state.email}
                      />
                    )}
                  </Translation>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-4">
                  <Translation>
                    {t => (
                      <input
                        type="text"
                        onChange={this.handleChange}
                        placeholder={t("student_University")}
                        id="university"
                        className="form-control form-control-lg"
                        value={this.state.university}
                      />
                    )}
                  </Translation>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-4">
                  <Translation>
                    {t => (
                      <input
                        type="text"
                        onChange={this.handleChange}
                        placeholder={t("Student_Select_College")}
                        id="faculty"
                        className="form-control form-control-lg"
                        value={this.state.faculty}
                      />
                    )}
                  </Translation>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-4">
                  <Translation>
                    {t => (
                      <input
                        type="text"
                        maxLength="14"
                        onChange={this.handleChange}
                        placeholder={t("student_National_id")}
                        id="national_id"
                        className="form-control form-control-lg"
                        value={this.state.national_id}
                      />
                    )}
                  </Translation>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mb-4">
                  <Translation>
                    {t => (
                      <input
                        type="text"
                        onChange={this.handleChange}
                        placeholder={t("student_Adress")}
                        id="address"
                        className="form-control form-control-lg"
                        value={this.state.address}
                      />
                    )}
                  </Translation>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 ml-auto">
                <Translation>
                  {t => (
                    <button className="btn btn-info btn-block btn-lg mt-4">
                      {" "}
                      {t("button_Next")}{" "}
                    </button>
                  )}
                </Translation>
              </div>
            </div>
          </form>
        ) : (
          <div className="text-center">
            <img
              src={success}
              className="img-fliud d-block mx-auto"
              alt="success"
            />
            <h5 className="text-center">
              {" "}
              Your details have been successfully registered - thanks for your
              trust{" "}
            </h5>
            <Link to="/" className="btn btn-info">
              {" "}
              Home{" "}
            </Link>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    studentStepOne: state.studentStepOne,
    studentStepTow: state.studentStepTow,
    studentStepThree: state.studentStepThree
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetState: () => {
      dispatch({
        type: "STEP_ONE_SUCSSESS",
        payload: null
      });
    },
    resetState2: () => {
      dispatch({
        type: "STEP_TOW_SUCSSESS",
        payload: null
      });
    },
    stepThree: data => dispatch(stepThree(data)),
    Done: () => {
      dispatch({
        type: "STEP_THREE_SUCSSESS",
        payload: null
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentStepThree);
