import React, { Component } from "react";
import { Navgator, SignForCourse, Chat } from "./navgator";


export default class Question extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <h1 className="h3 mt-5 mb-3">اسئلة واستفسارات عامة</h1>
        <div className="accordion" id="accordionExample">
          <div className="card">
            <div className="card-header" id="headingOne">
              <h2 className="mb-0">
                <button
                  className="btn btn-link"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  هل متاح الاستفادة بالعرض والحجز الان وتاجيل الدراسة؟
                </button>
              </h2>
            </div>

            <div
              id="collapseOne"
              className="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                نعم متاح التاجيل قبل بدء الدراسة لمدة ٣ شهور.
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="headingTwo">
              <h2 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  هل متاح شهادة وما جهة اعتمادها؟
                </button>
              </h2>
            </div>
            <div
              id="collapseTwo"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                متاح شهادة معتمدة من ريل سوفت وايضا ممكن اعتمادها من جهة حكومية
                مصرية حسب تعاقد الشركة وقتها.
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="headingThree">
              <h2 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  كيف يمكن عمل اختبار تحديد المستوي؟
                </button>
              </h2>
            </div>
            <div
              id="collapseThree"
              className="collapse"
              aria-labelledby="headingThree"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                {" "}
                يمكن عمل اختبار تحديد مستوي عن طريق موقع الشركة لقياس المعلومات
                العامة عن اللغة وعن طريق الهاتف لقياس مهارات التحدث.
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-header" id="headingThree4">
              <h2 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseThree4"
                  aria-expanded="false"
                  aria-controls="collapseThree4"
                >
                  للتواصل والاستفسار:
                </button>
              </h2>
            </div>
            <div
              id="collapseThree4"
              className="collapse"
              aria-labelledby="headingThree4"
              data-parent="#accordionExample"
            >
              <div className="card-body"> الخط الساخن 19103</div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <Navgator prev="PaymentMethod" next="PrevStudents" setNav={this.props.setItem} />
        </div>
      </div>
    );
  }
}
